import { Routes, Route, Outlet, Link, Navigate, BrowserRouter } from "react-router-dom";
import React, { useState, useEffect } from "react";

import "./firebaseui.css"
import DashContainer from "./Screens/DashContainer";
import firebase from 'firebase/compat/app';
import './scss/fonts/embedFonts.css'
import Documentation from "./Screens/Documentation";

// test
import { Widget, addResponseMessage, toggleMsgLoader } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import './ds_styles.css'
import Blog from "./Blog/Template";

// const ProtectedRoute = ({ isAuthenticated, children }) => {


//   if (!isAuthenticated) {

//     return <Navigate to="/" replace />;
//   }

//   return children;
// };


if (process.env.NODE_ENV === 'development') {
  window.$ipRiskAPI = 'http://localhost:5000'
   

}

if (process.env.NODE_ENV === 'production') {
  window.$ipRiskAPI = 'https://data-snack-prod-api-432419.uc.r.appspot.com'
}


const App = ({ app, ui }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [user, setUser] = useState({})
  const [chatWindowOpen, setChatWindowOpen] = useState(true);
  const [allMessages, setAllMessages] = useState([])

  const handleToggle = (open) => {
    setChatWindowOpen((prev) => !prev);
  };


  const handleNewUserMessage = (newMessage) => {
    let tempMessages = [...allMessages];
    tempMessages.push(
      { "sender": "DataSnack Bot", "message": newMessage }
    )
    toggleMsgLoader()
    fetch(`${window.$ipRiskAPI}/api/v1/domainBot`, {
      method: 'POST',
      body: JSON.stringify({
        contentSiloId: "0fd073f9-bbbd-4ed5-86e4-9cce8761618a",
        clientCompanyId: "d8124667-a299-473d-97b2-7bd8b327dd2a",
        messages: tempMessages
      }),
    }).then((res) => res.json())
      .then((data) => {
        toggleMsgLoader()
        addResponseMessage(data.message);

        tempMessages.push(data)
        setAllMessages(tempMessages)
        console.log("result from ds bot", data)

      }).catch(error => {
        toggleMsgLoader()
        console.log("error from bot", error)
        addResponseMessage("There was a problem processing your message. Please try again.");
      });
  }


  useEffect(() => {
    addResponseMessage("Welcome to DataSnack's Live AI Chatbot! Ask us anything and receive both answers to your questions and a DataSnack SnackRisk score.");
  }, []);




  const signOut = () => {
    app.signOut()
  }

  useEffect(() => {
    console.log("App.js useEffect called w/ listener")
    let unsubscribe = firebase.auth().onIdTokenChanged((user) => {
      if (user) {
        setUser(user)
        setIsAuthenticated(true)
        console.log("user", user)

        window.$tokenRefreshTime = user.multiFactor.user.auth.currentUser.stsTokenManager.expirationTime
        console.log("expiration time", window.$tokenRefreshTime)
        console.log("is expired", user.multiFactor.user.auth.currentUser.stsTokenManager.isExpired)
        localStorage.setItem('token', user.multiFactor.user.accessToken)
        console.log("USER ID:", user.multiFactor.user.uid)
        if (user.multiFactor.user.emailVerified) {
          setIsEmailVerified(true)
        }

      } else {
        console.log("Problem getting token")
      }
    })

    return () => {
      unsubscribe();
    }

  }, [])



  return (
    <div>


      <data-snack-widget />



      <BrowserRouter>

        <Routes>

          <Route path="/" element={<DashContainer user={user} isAbout={false}
            signOut={signOut} isAuthenticated={isAuthenticated} isEmailVerified={isEmailVerified} setIsAuthenticated={setIsAuthenticated} ui={ui}
          />} />

          <Route path="/chatbot" element={<DashContainer user={user} isAbout={false} goToPage={"HomePageChat"}
            signOut={signOut} isAuthenticated={isAuthenticated} isEmailVerified={isEmailVerified} setIsAuthenticated={setIsAuthenticated} ui={ui}
          />} />

          <Route path="/contact" element={<DashContainer user={user} isAbout={false} goToPage={"Contact"}
            signOut={signOut} isAuthenticated={isAuthenticated} isEmailVerified={isEmailVerified} setIsAuthenticated={setIsAuthenticated} ui={ui}
          />} />

          <Route path="/blog" element={<DashContainer user={user} isAbout={false} goToPage={"Blog"}
            signOut={signOut} isAuthenticated={isAuthenticated} isEmailVerified={isEmailVerified} setIsAuthenticated={setIsAuthenticated} ui={ui}
          />} />

          <Route path="/docs" element={<DashContainer user={user} isAbout={false} goToPage={"Documentation"}
            signOut={signOut} isAuthenticated={isAuthenticated} isEmailVerified={isEmailVerified} setIsAuthenticated={setIsAuthenticated} ui={ui}
          />} />


          <Route path="*" element={<DashContainer user={user} isAbout={false}
            signOut={signOut} isAuthenticated={isAuthenticated} isEmailVerified={isEmailVerified} setIsAuthenticated={setIsAuthenticated} ui={ui}
          />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

const NoMatch = () => {
  return (
    <div className='Container'>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;

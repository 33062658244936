import React, { useState, useEffect, useRef } from 'react'
import axios from '../../Login/axiosConfig.js'

import {
    TextField, Divider,
    Button, FormGroup, FormControl, OutlinedInput, InputAdornment, IconButton, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Stack, Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { convertDomainToValidURL } from '../../Helpers/Helpers.js';


const RiskClientCompany = ({ fcn, clientCompanies }) => {

    const [companyName, setCompanyName] = useState("")
    const [companyWebsite, setCompanyWebsite] = useState("")



    const handleSubmit = () => {

        const register = {
            companyName,
            companyWebsite

        }
        console.log("data from submit", register)
        fcn(register)

    }

    return (
        <Stack direction="column" sx={{
            backgroundColor: "#F5F7F8", borderRadius: 5,
            padding: 2.5,
            paddingTop: 5,

        }} >

            <Typography marginTop={0} paddingRight={window.$isMobile ? 0 : "15%"} sx={{ fontSize: 26, typography: { fontFamily: "Roboto", fontWeight: 900 }, lineHeight: '90%', color: "#006ec2" }} fontStyle={"normal"} component="div">
            Add Your Company or Client Company Details
            </Typography>
            <Typography marginTop={1} paddingRight={window.$isMobile ? 0 : "15%"} sx={{ fontSize: 16, typography: { fontFamily: "Roboto", fontWeight: 400 }, lineHeight: '90%', color: "#37383a" }} fontStyle={"normal"} component="div">
             Remember to add your company or client company website as this info is used to maintain context and relevance.            </Typography>
            <Stack marginTop={3} marginBottom={10} spacing={3}>


               

                    <TextField
                        sx={{ marginBottom: 5 }}
                        autoFocus={false}
                        margin="dense"
                        onChange={(e) => {
                            setCompanyName(e.target.value)
                        }}
                        id="description"
                        label="Client Company"
                        type="text"
                        fullWidth
                        multiline
                        value={companyName}
                        variant="standard"
                    />

                    <TextField
                        sx={{ marginBottom: 5 }}
                        autoFocus={false}
                        margin="dense"
                        onChange={(e) => {
                            setCompanyWebsite(e.target.value)
                        }}
                        id="description"
                        label="Company Website"
                        type="text"
                        fullWidth
                        multiline
                        value={companyWebsite}
                        variant="standard"
                    />


                





                <Stack width={"100%"} direction="row" spacing={2} marginTop={2} justifyContent={"flex-end"} >

                    <Button onClick={() => {
                        setCompanyName("")
                        setCompanyWebsite("")
                    }}>Cancel</Button>
                    <Button onClick={handleSubmit} >Submit</Button>
                </Stack>

            </Stack>

            {clientCompanies && clientCompanies.map((e) => (
                <Stack key={e.companyName}>
                    <Typography
                        key={e.companyName}


                        sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 900 },
                            fontSize: window.$isMobile ? 42 : 64, lineHeight: '105%', color: "#212325"
                        }} fontStyle={"normal"}>
                        {e.companyName}
                    </Typography>
                    <Divider sx={{ marginBottom: 4, backgroundColor: "#006ec2", width: "100%", borderBottomWidth: 10 }} variant="middle" />
                </Stack>
            ))}


        </Stack>
    )

}

export default RiskClientCompany
import React, { useEffect, useState, useRef } from 'react'


import {
    createTheme,
    responsiveFontSizes,
    ThemeProvider,
} from '@mui/material/styles';
import { Typography, Stack, Button, Box, Chip, Backdrop, CircularProgress } from '@mui/material';
import Footer from '../Components/Footer.js'
import risk_image from '../assets/images/heroCustomer.png'
import fact_check from '../assets/homepage/fact_checking-new.webp'
import ds_side from '../assets/homepage/Homepage_Spot_1_1400.webp'
import Iframe from 'react-iframe'





let theme = createTheme();
theme = responsiveFontSizes(theme);

const Blog = ({ handleScreenChangeClick }) => {
    const [showProgress, setShowProgress] = useState(false)
 
    useEffect(() => {
        window.location.href = 'https://blog.datasnack.ai';
    }, [])

    return (
        <div style={{
            backgroundColor: "#ffffff",
            // width: "390px"
            width: "100%"
        }}>
       
        </div >
    )

}

export default Blog






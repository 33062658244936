import React, { useEffect } from 'react'
import { createTheme, } from '@mui/material/styles';


import { Alert,  AlertTitle, LinearProgress, Stack } from '@mui/material';



const ShowMessages = ({ msgObj, setShowMessages }) => {
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    window.scrollTo(0, 0)
    console.log("msgObj::: ", msgObj)
  }, []);

  useEffect(() => {
    if (!msgObj.timeInSecs) {
      // default 30 secs
      msgObj.timeInSecs = 60
    } 

  // console.log("time in sec", msgObj.timeInSecs)
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          //  setShowMessages(null)
           clearInterval(timer);
        }
        // console.log("progressTime",msgObj.timeInSecs)
        const diff = ((100 / msgObj.timeInSecs) * (Math.random() * 2)) / 2
        // console.log("diff", diff)
        return Math.min(oldProgress + (diff), 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

 



  return (
    // <ThemeProvider theme={theme}>
    <Stack sx={{ zIndex: (theme) => theme.zIndex.drawer + 1}}>

      <Alert
        onClose={() => {

          setShowMessages(null)
        }}
        severity={msgObj.alertType} sx={{
          '& .MuiAlert-message': {
            // display: '-webkit-box',
            textAlign: "left",
            width: "90%",
            // overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            // WebkitLineClamp: 3,
            paddingRight: "5%",
            fontSize: 12,
            typography: { fontFamily: "Roboto", fontWeight: 400 },
          },
          minHeight: 100,
          // maxHeight: 100,

        }}>
        <AlertTitle>{msgObj.title}</AlertTitle>
        {msgObj.message}

      </Alert>
      {msgObj.alertType == "info" && !msgObj.isGetStarted && <LinearProgress variant="determinate" value={progress} />}
    </Stack>

    // </ThemeProvider>
  )

}
export default ShowMessages



import React, { useEffect, useState } from 'react'
import {
    createTheme,
    responsiveFontSizes,
    ThemeProvider,
} from '@mui/material/styles';
import { Typography, Stack, Button, Box, Chip, Backdrop, CircularProgress, Divider } from '@mui/material';
import fact_check from '../assets/homepage/fact_checking-new.webp'


import Footer from '../Components/Footer.js'
import { CodeBlock, dracula } from "react-code-blocks";
// import hero_bg from '../assets/homepage/hero-bg.webp'
import hero_bg from '../assets/homepage/sanCarlos.png'

import home_bot from '../assets/homepage/risk-demo.png'
import risk_image from '../assets/images/heroCustomer.png'

import ds_side from '../assets/homepage/Homepage_Spot_1_1400.webp'



let theme = createTheme();
theme = responsiveFontSizes(theme);

const HomePage = ({ handleScreenChangeClick }) => {

    const [showProgress, setShowProgress] = useState(false)

    const [heroScrollShown, setHeroScrollShown] = useState(false)




    useEffect(() => {
        window.scrollTo(0, 0)
        console.log("homepage useffect")
        let scrollStatus = localStorage.getItem('heroScrollShown');
        if (scrollStatus) {
            setHeroScrollShown(true)
        }
        return () => {
            setShowProgress(false)
        }

    }, []);





    return (
        <div style={{
            backgroundColor: "#ffffff",
            // width: "390px"
            width: "100%"
        }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showProgress}

            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ThemeProvider theme={theme}>

                {/* Introducing the World's First AI Chatbot with Built-In Hallucination Prevention */}
                <Box sx={{

                    backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 25%,rgba(0,0,0,1) 100%), url(${hero_bg})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: window.$isMobile ? 1085 : 680,

                }}>
                    <Stack>
                        <Stack
                            paddingX={window.$isMobile ? 2.5 : 5}
                            paddingY={window.$isMobile ? 10 : 15}

                            alignItems={"center"}
                            spacing={window.$isMobile ? 0 : 10}

                            direction={window.$isMobile ? "column" : "row"} width={"100%"} justifyContent={"space-between"} >
                            <Stack spacing={0} direction={"column"} width={window.$isMobile ? "100%" : "100%"}>
                                <Typography

                                    paddingBottom={window.$isMobile ? 1 : 1}
                                    variant={window.$isMobile ? 'h1' : 'h2'}
                                    sx={{

                                        textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 900 },
                                        lineHeight: '100%', color: "#000000"
                                    }} fontStyle={"normal"}>
                                    {/* Managing Employee Access to <span style={{ color: "#ffffff" }}>Restricted </span> GenAI Responses */}
                                    Prevent GenAI from Exposing <span style={{ color: "#ffffff" }}>Confidential Information </span>Across Your Organization

                                </Typography>
                                <Typography
                                    variant={'h5'}
                                    paddingBottom={window.$isMobile ? 4 : 4} paddingRight={window.$isMobile ? "10%" : "15%"}
                                    sx={{

                                        textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: window.$isMobile ? 600 : 600 },
                                        lineHeight: '105%', color: "#ffffff"
                                    }} fontStyle={"normal"}>

                                    Real-time Analysis and Proactive Solutions for Secure and Compliant AI-Generated Content
                                </Typography>

                                <Typography
                                    variant={'body1'}
                                    paddingBottom={window.$isMobile ? 4 : 4} paddingRight={window.$isMobile ? "10%" : "15%"}
                                    sx={{

                                        textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: window.$isMobile ? 400 : 400 },
                                        lineHeight: '120%', color: "#ffffff"
                                    }} fontStyle={"normal"}>
                                    {window.$isMobile ? "" : "DataSnack offers a real-time risk analysis engine designed to manage and control employee access to sensitive and restricted generative AI responses, such as employee salary information. Our proactive solutions empower organizations to safeguard sensitive data instantly, ensuring compliance and secure decision-making processes."}
                                </Typography>

                                {window.$isMobile && <Stack paddingBottom={10} alignSelf={"center"} width={"100%"}>
                                    <img src={home_bot} alt="Location Image" style={{ width: "100%" }} />

                                </Stack>}

                                <Stack direction={window.$isMobile ? "column-reverse" : "row"} spacing={2} >


                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            handleScreenChangeClick({
                                                selectedIndex: "Contact"

                                            })
                                        }} sx={{ borderColor: "#ffffff", width: window.$isMobile ? "100%" : "200px", height: "45px" }}>
                                        <Typography
                                            variant={'body1'}
                                            sx={{

                                                textTransform: "none",
                                                typography: { fontFamily: "Roboto", fontWeight: 700 },

                                                color: "#ffffff"
                                            }} >
                                            Contact DataSnack

                                        </Typography>
                                    </Button>

                                    {/* <Button
                                        variant="contained"
                                        onClick={() => {
                                            handleScreenChangeClick({
                                                selectedIndex: "RiskAPI"

                                            })
                                        }} sx={{ backgroundColor: window.$isMobile ? "#000000" : "#66a8da", width: window.$isMobile ? "100%" : "325px", height: "45px" }}>
                                        <Typography
                                            variant={'body1'}
                                            sx={{

                                                textTransform: "none",
                                                typography: { fontFamily: "Roboto", fontWeight: 700 },

                                                color: "#ffffff"
                                            }} >
                                            Discover AI Risk Mitigation Solutions

                                        </Typography>
                                    </Button> */}

                                </Stack>

                            </Stack>

                            {!window.$isMobile && <Stack width={"80%"}>
                                <img src={home_bot} alt="Location Image" style={{ width: "100%" }} />

                            </Stack>}


                        </Stack>

                        {/* {window.$isMobile && <Divider sx={{ alignSelf: "center", backgroundColor: "#66a8da", opacity: 0.5, width: "75%", borderWidth: 0.5 }} variant="middle" />} */}


                    </Stack>
                </Box >



                {/* SnackRisk: AI Factual Risk & Policy Violation Mitigation */}
                <Box sx={{ backgroundColor: "#000000" }}>
                    <Stack paddingX={window.$isMobile ? 1.5 : 10}

                        paddingY={window.$isMobile ? 0 : 10}
                        spacing={5}>
                        {/* section title */}
                        <Stack direction={"column"}
                            paddingBottom={"5%"}
                            alignItems={"center"}>
                            <Typography
                                paddingX={window.$isMobile ? 0 : 15}
                                variant={'h3'}

                                sx={{

                                    typography: { fontFamily: "Roboto", fontWeight: 300 },
                                    textAlign: "center",
                                    lineHeight: '105%', color: "#ffffff"
                                }} >
                                Safeguard sensitive data with DataSnack's real-time risk analysis engine—ensuring secure and compliant AI-generated content.
                            </Typography>

                        </Stack>



                        {/* Drive Efficiency, Boost Revenue, and Deliver Unmatched 24/7 Support*/}
                        <Stack
                            sx={{ backgroundColor: "#ffffff", borderRadius: 2.5 }}>


                            <Stack direction={window.$isMobile ? "column" : "row"} width={"100%"}
                                paddingX={2.5}
                                paddingY={7.5}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                spacing={5}

                            >
                                <Stack width={"100%"}>
                                    <Stack width={"25%"} paddingBottom={5}>
                                        <Chip label="Trust" sx={{ color: "#ffffff", backgroundColor: "#66a8da" }} />
                                    </Stack>


                                    <Typography
                                        // variant={'h3'}
                                        variant={'h4'}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 900 },
                                            textAlign: "left",
                                            lineHeight: '105%', color: "#00000"
                                        }} >
                                        Addressing Enterprise Concerns and Building Trust
                                    </Typography>
                                    <Typography
                                        marginTop={2}
                                        // variant={'body1'}
                                        variant={'body1'}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 400 },
                                            textAlign: "left",
                                            lineHeight: '105%', color: "#00000"
                                        }} >
                                        Enterprise companies recognize the critical importance of managing GenAI risk. However, top barriers to successful GenAI deployment include concerns about regulatory compliance (36%), difficulty managing risks (30%), and lack of a governance model (29%). DataSnack helps address these barriers by providing robust guardrails and oversight capabilities.
                                        <br /><br />
                                        <ul>
                                            <li><b>Governance Framework: </b>
                                                Establish a solid governance framework for using GenAI tools and applications, ensuring responsible use and compliance.</li>
                                            <br />
                                            <li><b>Regulatory Monitoring: </b>
                                                Stay ahead of regulatory requirements and ensure compliance with industry standards.</li>
                                            <br />
                                            <li><b>Internal Audits and Testing: </b>
                                                Conduct thorough internal audits and testing on GenAI tools and applications to maintain security and compliance.</li>
                                            <br />
                                            <li><b>Measuring Impact: </b>
                                                Define and measure the impact of GenAI efforts with specific KPIs and regular reporting to the C-suite and boardroom, demonstrating value and maintaining support.</li>

                                        </ul>
                                        With DataSnack, organizations can confidently build trust and ensure responsible use of GenAI, making it a critical component in their AI strategy.
                                    </Typography>

                                </Stack>

                                <Stack width={"100%"} alignItems={"center"} paddingTop={window.$isMobile ? 0 : 10}>

                                    <img src={fact_check} alt="Location Image" style={{ width: "100%" }} />



                                </Stack>

                            </Stack>
                        </Stack>

                        {/* Transform Your Customer Interactions */}
                        <Stack
                            sx={{ backgroundColor: "#ffffff", borderRadius: 2.5 }}>


                            <Stack direction={window.$isMobile ? "column" : "row"} width={"100%"}
                                paddingX={2.5}
                                paddingY={7.5}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                spacing={5}

                            >
                                <Stack width={"100%"}>
                                    <Stack width={"40%"} paddingBottom={5}>
                                        <Chip label="Restricted Information" sx={{ color: "#ffffff", backgroundColor: "#66a8da" }} />
                                    </Stack>
                                    <Typography
                                        variant={'h4'}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 900 },
                                            textAlign: "left",
                                            lineHeight: '105%', color: "#00000"
                                        }} >
                                        Illustrative Examples of Restricted Information and Policy Violations in AI-Generated Content
                                    </Typography>
                                    <Typography
                                        marginTop={2}
                                        variant={'body1'}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 400 },
                                            textAlign: "left",
                                            lineHeight: '105%', color: "#00000"
                                        }} >

                                        DataSnack provides robust solutions to manage these issues by offering real-time risk analysis and integrating customizable policies into its knowledge graph. Leveraging DataSnack, organizations can ensure that AI-generated content adheres to access controls and user permissions, reducing the risk of exposing restricted information. Additionally, the platform's real-time risk analysis engine highlights discrepancies and policy violations instantly, allowing organizations to rectify issues before they impact security and compliance.


                                        <ul>
                                            <li><b>Restricted Information Exposure:</b> An LLM generates a response that includes specific employee salary details in a report accessible to all staff. This exposure of restricted information constitutes a serious compliance breach, as it reveals sensitive data to unauthorized employees.  </li>
                                            <br />
                                            <li>   <b>Internal Policy Violations:</b> An organization uses an LLM to generate internal communications, and the AI inadvertently includes proprietary information that should be restricted to specific departments. This violation of internal policies poses security and compliance risks, emphasizing the need to ensure AI-generated content aligns with access controls and internal guidelines.  </li>
                                        </ul>
                                    </Typography>

                                </Stack>

                                <Stack width={window.$isMobile ? "300px" : "650px"} height={window.$isMobile ? "600px" : "650px"} paddingTop={window.$isMobile ? 0 : 10}>
                                    <Typography
                                        variant={'h5'}
                                        paddingBottom={2}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 900 },
                                            textAlign: "center",
                                            lineHeight: '105%', color: "#00000"
                                        }} >
                                        Example SnackRisk Engine Response
                                    </Typography>
                                    <CodeBlock
                                        text={risk_json}
                                        language={"json"}
                                        showLineNumbers={false}
                                        startingLineNumber={1}
                                        theme={dracula}
                                    />

                                </Stack>

                            </Stack>
                        </Stack>
                    </Stack>
                </Box>

                {/* DataSnack's SnackRisk Engine Use Cases */}
                <Box sx={{ backgroundColor: "#ffffff" }}>

                    <Stack direction={window.$isMobile ? "column" : "row"} width={"100%"}

                        justifyContent={"space-between"}
                        spacing={5}
                        alignItems={"center"}
                        paddingX={window.$isMobile ? 2.5 : 10}
                        paddingY={10}

                    >

                        <Stack width={"100%"}>
                            <Typography
                                // paddingRight={50}
                                // paddingX={window.$isMobile ? 2.5 : 10}
                                variant={'h4'}
                                sx={{

                                    typography: { fontFamily: "Roboto", fontWeight: 900 },
                                    textAlign: "left",
                                    lineHeight: '105%', color: "#000000"
                                }} >
                                DataSnack's SnackRisk Engine Use Cases
                            </Typography>
                            <Typography
                                marginTop={2}
                                variant={'body1'}
                                sx={{

                                    typography: { fontFamily: "Roboto", fontWeight: 400 },
                                    textAlign: "left",
                                    lineHeight: '105%', color: "#000000"
                                }} >
                                Across various industries, managing access to restricted information in AI-generated content is critical. DataSnack's Risk API offers a versatile solution for mitigating these risks, making it essential for a wide range of use cases across different sectors.                                  <ul>
                                    <li><b>Compliance Monitoring</b>: Organizations in highly regulated industries such as finance or healthcare can use DataSnack's Risk API to ensure that AI-generated content adheres to industry-specific regulations and compliance standards, mitigating the risk of unauthorized access to sensitive data.  </li>
                                    <br /><li><b>Content Access Control</b>: Media and publishing organizations can utilize DataSnack's Risk API to verify that AI-generated content does not expose restricted information, ensuring that published material complies with access permissions and minimizes security risks.  </li>
                                    <br /><li><b>Employee Communication Management</b>: Companies deploying AI-powered internal communication systems can integrate DataSnack's Risk API to validate that responses provided by these AI systems do not disclose restricted information, ensuring that internal communications are consistent with company policies and access controls. </li>
                                </ul>
                            </Typography>

                        </Stack>

                        <Stack width={"100%"} alignItems={"flex-start"}>
                            <img src={risk_image} alt="Location Image" style={{ width: "100%" }} />


                        </Stack>


                    </Stack>

                </Box>

                {/* Why Choose DataSnack? */}
                <Box sx={{ backgroundColor: "#f4f3ec" }}>

                    <Stack direction={window.$isMobile ? "column" : "row"} width={"100%"}
                        alignItems={"center"}

                        justifyContent={"space-between"}
                        spacing={5}
                        paddingX={window.$isMobile ? 2.5 : 10}
                        paddingY={10}

                    >

                        <Stack width={"100%"}>
                            <Typography
                                // paddingRight={50}
                                // paddingX={window.$isMobile ? 2.5 : 10}
                                variant={'h4'}
                                sx={{

                                    typography: { fontFamily: "Roboto", fontWeight: 900 },
                                    textAlign: "left",
                                    lineHeight: '105%', color: "#000000"
                                }} >
                                Why Choose DataSnack's SnackRisk Engine?
                            </Typography>
                            <Typography
                                marginTop={2}
                                variant={'body1'}
                                sx={{

                                    typography: { fontFamily: "Roboto", fontWeight: 400 },
                                    textAlign: "left",
                                    lineHeight: '105%', color: "#000000"
                                }} >
                                DataSnack offers a compelling solution for organizations seeking to manage employee access to restricted generative AI responses, providing a robust foundation for ensuring the security and compliance of their data and insights.
                                <ul>
                                    <li><b>Mitigates Risk</b>: Reduces risks associated with unauthorized access to restricted generative AI responses, ensuring the security and compliance of organizational data and insights.  </li>
                                    <br /><li><b>Real-Time</b>: Empowers organizations with real-time risk analysis and customizable policy creation to control access before issues impact security and compliance.
                                    </li>
                                    <br /><li><b>Fosters Trust</b>: Proactively ensures timely, accurate responses that align with internal guidelines and regulatory standards, fostering trust and operational efficiency.  </li>
                                </ul>
                                By leveraging DataSnack's real-time risk analysis, customizable policies, and proactive rectification mechanisms, organizations can confidently navigate the complexities of AI-generated content, ensuring secure, compliant responses that align with internal guidelines and regulatory standards, ultimately fostering trust and operational efficiency.
                            </Typography>

                        </Stack>

                        <Stack width={"100%"} alignItems={"center"}>
                            <img src={ds_side} alt="Location Image" style={{ width: "45%" }} />


                        </Stack>

                    </Stack>

                </Box>

                {/* Simple, transparent pricing */}
                <Box sx={{ backgroundColor: "#ffff66" }}>
                    <Stack paddingX={window.$isMobile ? 2.5 : 20}
                        paddingY={10} spacing={5}>



                        <Stack
                            sx={{ backgroundColor: "#ffffff" }}>


                            <Stack direction={window.$isMobile ? "column" : "row"} width={"100%"}
                                paddingX={2.5}
                                paddingY={7.5}
                                justifyContent={"center"}
                                alignItems={"center"}
                                spacing={window.$isMobile ? 5 : 2}
                                sx={{ border: 1, borderColor: "#212325" }}
                            >
                                <Stack width={"100%"} direction={"column"} spacing={5} alignItems={"center"}>

                                    <Typography
                                        variant={'h4'}
                                        paddingLeft={window.$isMobile ? 0 : 7.5}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 500 },
                                            textAlign: window.$isMobile ? "center" : "left", fontStyle: "italic",
                                            lineHeight: '105%', color: "#00000"
                                        }} >
                                        Simple, transparent pricing


                                    </Typography>


                                </Stack>




                                <Stack width={"100%"} direction={"column"} spacing={3} alignItems={"center"} >
                                    <Typography
                                        marginTop={2}
                                        variant={'h2'}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 900 },
                                            textAlign: "center",
                                            lineHeight: '105%', color: "#00000"
                                        }} >
                                        Starting at <br />$349 / mo.


                                    </Typography>
                                    <Button
                                        variant='outlined'
                                        onClick={() => {
                                            handleScreenChangeClick({
                                                selectedIndex: "Contact"

                                            })
                                        }} sx={{ borderColor: "#000000 ", width: window.$isMobile ? "100%" : "300px", height: "45px" }}>
                                        <Typography
                                            variant={'h7'}
                                            sx={{

                                                textTransform: "none",
                                                typography: { fontFamily: "Roboto", fontWeight: 700 },

                                                color: "#000000"
                                            }} >
                                            Contact DataSnack

                                        </Typography>
                                    </Button>

                                </Stack>

                            </Stack>
                        </Stack>


                    </Stack>
                </Box>

                {/* Implement DataSnack's AI Chatbot today to elevate engagement and differentiate your business in the industry. */}
                <Box sx={{

                    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.05'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
                    backgroundColor: "#212325",


                }}>
                    <Stack paddingX={2.5}
                        paddingY={20} direction={"column"} width={"100%"}
                        spacing={2}
                        alignItems={"center"}

                    >

                        <Typography
                            variant={'h3'}
                            paddingX={window.$isMobile ? 0 : 10}
                            sx={{

                                typography: { fontFamily: "Roboto", fontWeight: 300 },
                                textAlign: "center",
                                lineHeight: '105%', color: "#ffffff"
                            }} >
                            Safeguard your organization with <b>confidence, security, and compliance.</b>
                        </Typography>
                        <Button
                            variant='outlined'
                            onClick={() => {
                                handleScreenChangeClick({
                                    selectedIndex: "Contact"

                                })
                            }} sx={{ borderColor: "#ffffff ", width: window.$isMobile ? "100%" : "200px", height: "45px" }}>
                            <Typography
                                variant={'h7'}
                                sx={{

                                    textTransform: "none",
                                    typography: { fontFamily: "Roboto", fontWeight: 700 },

                                    color: "#ffffff"
                                }} >
                                Contact DataSnack

                            </Typography>
                        </Button>
                    </Stack>


                </Box>
                <Footer handleScreenChangeClick={handleScreenChangeClick} backgroundColor={"#000000"} />
            </ThemeProvider>

        </div >
    )

}

export default HomePage

const risk_json = `{
    "clientCompanyId": "0378ee89-9a73-42e2-9a62-22a46167cf65",
    "contentSiloId": "default",
    "details": {
        "clientCompanyId": "0378ee89-9a73-42e2-9a62-22a46167cf65",
        "content": "Whether your insurance covers landscaping damage caused by a house fire depends on the specifics of your homeowners insurance policy. Generally, standard homeowners insurance policies cover damage to the structure of your home and personal belongings due to fire. However, coverage for landscaping, including trees, shrubs, and other plants, can vary.\n\nHere are some key points to consider:\n\n1. **Dwelling Coverage**: This typically covers the structure of your home and may extend to attached structures like garages. Landscaping is usually not included under this part of the policy.\n\n2. **Other Structures Coverage**: This part of your policy covers structures not attached to your home, such as sheds or fences. Some policies might extend limited coverage to landscaping under this section.\n\n3. **Personal Property Coverage**: This covers your personal belongings but usually does not extend to landscaping.\n\n4. **Additional Coverage for Landscaping**: Some policies offer specific coverage for landscaping, but it often comes with limits. For example, there might be a cap on the amount payable per tree, shrub, or plant, and an overall limit for landscaping damage.\n\n5. **Perils Covered**: If your policy includes coverage for landscaping, it will typically specify the perils covered. Fire is often one of the covered perils, but you should verify this in your policy.\n\n6. **Endorsements or Riders**: You may have the option to purchase additional coverage for landscaping through endorsements or riders. This can provide more comprehensive protection for your plants and trees.\n\nTo determine if your insurance covers landscaping damage caused by a house fire, review your policy documents or contact your insurance agent or company directly. They can provide specific information about your coverage limits and any applicable exclusions or conditions.",
        "contentSiloId": "default",
        "targetRecipientGroup": "customers"
    },
    "fullRiskReportId": "facf8e24-516c-4472-908a-66fb9b44751e",
    "riskReport": {
        "overallRiskLevel": "Low",
        "risks": [
            {
                "description": "The content may be misleading as it does not adequately address the limitations and coverage details related to landscaping damage caused by a house fire as per the referenced material.",
                "mitigationSuggestions": [
                    "Include a more detailed and accurate explanation of the limitations and coverage specifics mentioned in the policy documents.",
                    "Encourage customers to thoroughly review their policy documents or contact their insurance agent for specific information."
                ],
                "referenceSnippets": [
                    "This coverage does not increase the limit of liability applying to the property being repaired.",
                    "We will pay up to an additional 5% of the limit of liability shown on the Policy Declarations under Coverage A Dwelling Protection for loss to trees, shrubs, plants and lawns at the address of the residence premises. We will not pay more than $500 for any one tree, shrub, or plant including expenses incurred for removing debris."
                ],
                "riskId": "8d309077-d8d6-45a0-920f-4fa4575e920d",
                "riskLevel": 45,
                "riskType": "Research Insight"
            },
            {
                "description": "Disclosure of specific policy rules ('Internal Investigations') in the content may violate company confidentiality policies.",
                "mitigationSuggestions": [
                    "Ensure employees are trained on the company's confidentiality policies and guidelines for public communications.",
                    "Implement content review processes to catch any potential policy violations before publication."
                ],
                "referenceSnippets": [
                    "Internal Investigations Internal Investigations Internal Investigations"
                ],
                "riskId": "1dcca935-9f32-4ba1-bb4c-f19ab2940f04",
                "riskLevel": 37,
                "riskType": "Policy"
            },
            {
                "description": "There may be a factual error in the content regarding the coverage limits and payment details for landscaping damage caused by windstorm, hail, or weight of ice, snow, or sleet as per the referenced material.",
                "mitigationSuggestions": [
                    "Verify the accuracy of the payment details and coverage limits for landscaping damage caused by specific perils and update the content accordingly."
                ],
                "referenceSnippets": [
                    "We will pay up to $500 for reasonable expenses you incur for the removal of debris of trees at the address of the residence premises for direct physical loss caused by windstorm, hail, or weight of ice, snow or sleet."
                ],
                "riskId": "686d35e1-9aae-44bd-961d-7c1c8a055318",
                "riskLevel": 37,
                "riskType": "Research Insight"
            },
            {
                "description": "The tone of the content may come across as too technical and complex for the average customer to understand easily.",
                "mitigationSuggestions": [
                    "Simplify the language and explanations to make it more accessible to a broad audience.",
                    "Provide examples or scenarios to illustrate the points being made about insurance coverage.",
                    "Consider using visuals or infographics to enhance understanding."
                ],
                "riskId": "8cc9e272-cdcc-4d20-9a20-2ce3617b6616",
                "riskLevel": 5,
                "riskType": "Tone"
            }
        ]
    }
}`






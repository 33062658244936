import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import {
   Box, Stack
} from '@mui/material';



const Documentation = () => {
    let swaggerJSON = ""
    if (process.env.NODE_ENV === 'production') {
        // intentionally going to website backend
        swaggerJSON = 'https://datasnackapi.uc.r.appspot.com/api/v1/docs'
    } else {
        // data_snack_api server 
        swaggerJSON = 'http://localhost:5000/api/v1/docs'

    }

    return (
        <Box sx={{backgroundColor: "#ffffff"}}>
            <Stack >
        <SwaggerUI url={swaggerJSON} />
        </Stack>
        </Box>
    )
}
export default Documentation
import * as React from 'react';
import { CssBaseline, AppBar, Drawer, Toolbar, List, ListItemButton, ListItemText, Dialog, DialogContent, Button, Divider, DialogActions, DialogTitle, Typography, Box } from '@mui/material';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ShowMessages from '../Components/ShowMessages';
import Header from '../Components/Header';
import { useState, useEffect, useRef } from 'react'
import axios from '../Login/axiosConfig'
import ListSubheader from '@mui/material/ListSubheader';
import Admin from './Admin';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import "../scss/fonts/Courier_Prime/courier.css"

import { useNavigate, useSearchParams } from "react-router-dom";
import Feedback from './Feedback';
import Contact from './Contact';
import FeedbackIcon from '@mui/icons-material/Feedback';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Privacy from './Privacy';
import TOS from './TOS';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import ChatIcon from '@mui/icons-material/Chat';
import ReportIcon from '@mui/icons-material/Report';
import RiskCheckerDashboard from './RiskCheckerDashboard'
import Documentation from './Documentation';
import HelpWithBot from './HelpWithBot';
import HomePage from './HomePage';
import RiskAPI from './RiskAPI';
import Blog from '../Blog/Template';
import HomePageChat from './HomePageChat';

const drawerWidth = 230;



export default function DashContainer({ userFirebase, goToPage, signOut, isAuthenticated, isEmailVerified, setIsAuthenticated, ui }) {
  // console.log("userFirebase, isAuthenticated, isEmailVerified, ", userFirebase, isAuthenticated, isEmailVerified)
  const [sidebar, setSidebar] = useState("HomePage")
  const [emailOpen, setEmailOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [deepDive, setDeepDive] = useState({});
  const [updateDash, setUpdateDash] = useState(false);
  const [showMessages, setShowMessages] = useState(null);
  const [updater, setUpdater] = useState(0);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [gameData, setGameData] = useState(null)
  const [allCampaigns, setAllCampaigns] = useState(null)
  const [dataSnackExamples, setDataSnackExamples] = useState(null)
  const [dataSnackGames, setDataSnackGames] = useState(null)
  const [campaignAnalysis, setCampaignAnalysis] = useState(null)
  const [apiKey, setApiKey] = useState(null)

  const [gameDetails, setGameDetails] = useState(null)


  const [searchParams, setSearchParams] = useSearchParams();
  const q = searchParams.get('q');
  const id = searchParams.get('id')


  const typewriterAudioRef = useRef(null)
  const chaosAudioRef = useRef(null)
  const sentMessageAudioRef = useRef(null)
  const waitingAudioRef = useRef(null)

  const soundEffectObj = useRef({
    typewriterAudioRef,
    chaosAudioRef,
    sentMessageAudioRef,
    waitingAudioRef
  })


  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    console.log("dash container use effect")
    if (isAuthenticated) {
      getCurrentUser()
    }

    console.log("gotopage:", goToPage)
    if (goToPage !== undefined) {
      setSidebar(goToPage)
    }

  }, [isAuthenticated])


  const createNewAPIKey = () => {
    axios.get(`/api/v1/createApiKey`)
      .then(response => {
        console.log("apiKey", response.data)
        setApiKey(response.data)

      }).catch(error => {
        // need to figure out way to handle user swiping away from screen and waiting
        console.log("createApiKey error:", error)
      })
  }




  const getCurrentUser = async () => {
    try {
      console.log("in fetch data")
      const response = await axios.get('/api/v1/getUser', {
        timeout: 10000
      });
      console.log("User:", response.data)
      setUser(response.data)


    } catch (error) {
      console.log(error)
      setShowMessages({
        alertType: "error",
        title: "Problem getting user details",
        message: "Please try back at a later time",
      })
    }
  }


  const handleClose = () => {
    setOpen(false)
  }

  const handleEmailClose = () => {
    setEmailOpen(false)
  }

  const handleSubmit = (register) => {


    console.log("data from submit", register)

    axios.post(window.$ip + '/api/v1/addUser', register)
      .then(response => {
        setOpen(false)
        setIsAuthenticated(true)
        navigate("/")
        setUser(response.data)

      }).catch(error => {

        console.log("error", error)
      });
  }

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        console.log("auth result:", authResult)
        // check if new signup
        if (authResult.additionalUserInfo.isNewUser) {
          let register = {
            fullName: authResult.user.multiFactor.user.displayName,
            email: authResult.user.multiFactor.user.email,
            userId: authResult.user.multiFactor.user.uid,
            accountValidated: authResult.user.multiFactor.user.emailVerified
          }
          handleSubmit(register)
          setShowMessages({
            alertType: "success",
            title: "Done",
            message: "Successfully signed up.",
          })
          setSidebar("AllCampaigns")

        } else {
          setIsAuthenticated(true)
          navigate("/")
          setShowMessages({
            alertType: "success",
            title: "Done",
            message: "Successfully signed in.",
          })
        }

        // User successfully signed in.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        return false;
      },
      // uiShown: function () {
      //     // The widget is rendered.
      //     // Hide the loader.
      //     document.getElementById('loader').style.display = 'none';
      // }
    },
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: 'popup',
    // signInSuccessUrl: '/dashboard',
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      // firebase.auth.GithubAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      // firebase.auth.PhoneAuthProvider.PROVIDER_ID
    ],
    // Terms of service url.
    // tosUrl: '<your-tos-url>',
    // Privacy policy url.
    // privacyPolicyUrl: '<your-privacy-policy-url>'
  };

  const renderDashScreen = () => {
    console.log("in render...", sidebar)
    switch (sidebar) {




      case "Contact":
        return (
          <>
            <Contact
              setShowMessages={setShowMessages}
              dashData={deepDive}
              handleScreenChangeClick={handleScreenChangeClick} updater={updater} />
          </>
        )

      case "HelpWithBot":
        return (
          <HelpWithBot
            setShowMessages={setShowMessages}
            dashData={deepDive}
            handleScreenChangeClick={handleScreenChangeClick} updater={updater}
          />
        )

     

      case "Documentation":
        return (
          <Documentation />
        )

        case "Blog":
          window.location.href = 'https://blog.datasnack.ai';
          return (
            <HomePage
            handleScreenChangeClick={handleScreenChangeClick}
          />
           
          )



      case "Feedback":
        return (
          <>
            <Feedback
            
              setShowMessages={setShowMessages}
              handleScreenChangeClick={handleScreenChangeClick} />
          </>
        )

      case "Admin":
        return (
          <>
            <Admin
              isAuthenticated={isAuthenticated}
             
              apiKey={apiKey}
              setApiKey={setApiKey}
              createNewAPIKey={createNewAPIKey}
           
              setShowMessages={setShowMessages}
              handleScreenChangeClick={handleScreenChangeClick} />
          </>
        )

      case "RiskAPI":
        return (
          <>
            <RiskAPI
              handleScreenChangeClick={handleScreenChangeClick}
            />
          </>
        )

      case "HomePage":
        return (
          <>
            <HomePage
              handleScreenChangeClick={handleScreenChangeClick}
            />
          </>
        )

        case "HomePageChat":
          return (
            <>
              <HomePageChat
                handleScreenChangeClick={handleScreenChangeClick}
              />
            </>
          )

  

      case "RiskCheckerDashboard":
        return (
          <RiskCheckerDashboard user={user} initTab={deepDive.initTab} setShowMessages={setShowMessages} setUser={setUser} isAuthenticated={isAuthenticated} />
        )

     
      case "Privacy":
        return (
          <>
            <Privacy
              handleScreenChangeClick={handleScreenChangeClick} />
          </>
        )

      case "TOS":
        return (
          <>
            <TOS
              handleScreenChangeClick={handleScreenChangeClick} />
          </>
        )

      default:
        return (
          <>
            <HomePage
              handleScreenChangeClick={handleScreenChangeClick}
            />
          </>
        )
    }

  }

  const handleScreenChangeClick = (data) => {
    console.log("handleScreenChangeClick:", data)
    setUpdater(Math.floor(Math.random() * 10000))
    setDeepDive(data)
    setSidebar(data.selectedIndex)
    if (data.updateDash) {
      setUpdateDash(!updateDash)
    }
    if (data.contentMessage) {
      console.log("data.contentMessage: ", data.contentMessage)
      setTimeout(() => {
        setShowMessages(data.contentMessage)
      }, 250)


    }


  }



  const openLogin = () => {
    setOpen(true)
    ui.start('#firebaseui-auth-container', uiConfig);
    setMobileOpen(false)
  }



  const NestedList = () => {


    return (
      // <ThemeProvider theme={theme}>
      <List
        sx={{
          width: '100%', maxWidth: 360, bgcolor: '#212325', '& .Mui-selected': {
            backgroundColor: '#37383a',
          },
        }}
        selected
        component="nav"
        aria-labelledby="nested-list-subheader"

      >

        <ListSubheader component="div" id="nested-list-subheader" sx={{ backgroundColor: "#212325", marginTop: 2, marginBottom: 2, fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 700 }, lineHeight: '90%', color: "#ffffff" }}>
          Home
        </ListSubheader>

        <ListItemButton
          sx={{ pl: 4 }}
          selected={sidebar === "HomePage"}
          onClick={() => {
            setSidebar("HomePage")
            setMobileOpen(false)
          }}
        >

          <ReportIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>SnackRisk</Typography>}
          />
        </ListItemButton>

{/* 
        <ListItemButton
          sx={{ pl: 4 }}
          selected={sidebar === "HomePageChat"}
          onClick={() => {
            setSidebar("HomePageChat")
            setMobileOpen(false)
          }}
        >

          <ChatIcon  sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>SnackBot</Typography>}
          />
        </ListItemButton> */}

        
        <ListItemButton
          sx={{ pl: 4 }}
          selected={sidebar === "Blog"}
          onClick={() => {
            setSidebar("Blog")
            setMobileOpen(false)
          }}
        >
 
          <ReportIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>Blog</Typography>}
          />
        </ListItemButton>

        {/* <ListItemButton sx={{ pl: 4 }}
          selected={sidebar === "RiskCheckerDashboard"}
          onClick={() => {
            // if (!isAuthenticated) {
            //   setShowMessages({
            //     alertType: "error",
            //     title: "Not Logged In",
            //     message: "Please create an account of log in to view campaigns",
            //   })
            //   setMobileOpen(false)
            //   return
            // }
            setSidebar("RiskCheckerDashboard")
            setMobileOpen(false)

          }}>

          <SpaceDashboardIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>Dashboard</Typography>}
          />
        </ListItemButton> */}

      
<ListItemButton sx={{ pl: 4 }}
          selected={sidebar === "Documentation"}
          onClick={() => {
            setSidebar("Documentation")
            setMobileOpen(false)

          }}>

          <DescriptionIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>Documentation</Typography>}
          />
        </ListItemButton>
     
        <ListSubheader component="div" id="nested-list-subheader" sx={{ backgroundColor: "#212325", marginTop: 2, marginBottom: 2, fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 700 }, lineHeight: '90%', color: "#ffffff" }}>
          Admin
        </ListSubheader>

        <ListItemButton sx={{ pl: 4 }}
          selected={sidebar === "Admin"}
          onClick={() => {
            if (!isAuthenticated) {
              setShowMessages({
                alertType: "error",
                title: "Not Logged In",
                message: "Please create an account of log in to Admin",
              })
              setMobileOpen(false)
              return
            }
            setSidebar("Admin")
            setMobileOpen(false)

          }}>

          <AccountBoxIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>Account</Typography>}
          />
        </ListItemButton>

     



        <ListItemButton sx={{ pl: 4 }}
          selected={sidebar === "Feedback"}
          onClick={() => {
            setSidebar("Feedback")
            setMobileOpen(false)

          }}>

          <FeedbackIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>Submit Feedback</Typography>}
          />
        </ListItemButton>

        <ListItemButton sx={{ pl: 4 }}
          selected={sidebar === "Contact"}
          onClick={() => {
            setSidebar("Contact")
            setMobileOpen(false)

          }}>

          <ContactPageIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>Contact Us</Typography>}
          />
        </ListItemButton>

        {!isAuthenticated && <ListItemButton sx={{ pl: 4 }}

          onClick={openLogin}>

          <LogoutIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>Sign in / Sign up</Typography>}
          />
        </ListItemButton>}

        {isAuthenticated && <ListItemButton sx={{ pl: 4 }}

          onClick={() => {
            setIsAuthenticated(false)
            localStorage.removeItem('token');
            setShowMessages({
              alertType: "success",
              title: "Done",
              message: "Successfully signed out.",
            })
            setMobileOpen(false)
            setApiKey(null)
            signOut()
            setSidebar("HomePage")

          }}>

          <LogoutIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>Logout</Typography>}
          />
        </ListItemButton>}




      </List>
      // </ThemeProvider>

    );
  }


  return (

    <Box sx={{ display: 'flex' }}>
      <Dialog
        open={open}
        fullWidth={true}
        keepMounted={true}
        sx={{
          //You can copy the code below in your theme
          // background: "#000", // Change to 'red' for example to make it more visible
          "& .MuiPaper-root": {
            background: "transparent", // Change to 'red' for example to make it more visible
            boxShadow: "none",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent" // Try to remove this to see the difference
          }
        }}
        onClose={handleClose}>

        <div style={{
          fullWidth: true,
        }} id="firebaseui-auth-container"></div>


      </Dialog>

      {!isEmailVerified && (
        <Dialog
          open={emailOpen}
          fullWidth={true}


          onClose={handleEmailClose}>
          <DialogTitle sx={{ typography: { fontFamily: "Roboto", fontWeight: 900 }, fontSize: 22, color: "#212325" }}>
            Email verification needed
          </DialogTitle>

          <DialogContent>
            <Typography sx={{ fontSize: 12, typography: { fontFamily: "CourierPrime-Regular" }, color: "#212325" }} fontStyle={"normal"} >
              Please check your recent emails for a verification link. Please click SEND to receive a new verification email.
            </Typography>

          </DialogContent>

          <DialogActions style={{ marginTop: 2 }}>
            <Button sx={{ typography: { fontFamily: "CourierPrime-Regular" }, fontSize: 12, color: "#212325" }}
              onClick={handleEmailClose}>Cancel</Button>
            <Button sx={{ typography: { fontFamily: "CourierPrime-Regular" }, fontSize: 12, color: "#212325" }}
              onClick={() => {

                firebase.auth().currentUser.sendEmailVerification()
                handleEmailClose()
              }}>Send</Button>
          </DialogActions>

        </Dialog>
      )}
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}

      >
        <Toolbar sx={{ backgroundColor: "#ffffff" }}>


          <Header
            currentScreen={sidebar}
            setSidebar={setSidebar} user={user}
            handleDrawerToggle={handleDrawerToggle}
            setShowMessages={setShowMessages}
            handleScreenChangeClick={handleScreenChangeClick}

            isAuthenticated={isAuthenticated} />

        </Toolbar>
      </AppBar>


      <Drawer

        sx={{
          // display: { xs: 'block', sm: 'none' },

          '& .css-10hburv-MuiTypography-root.css-10hburv-MuiTypography-root': {
            fontFamily: "Roboto", fontWeight: 400,
            fontSize: 12,
            color: "#212325"
          },
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: "#212325",

            // fontFamily: "Roboto", fontWeight: 300,

          },
        }}
        // variant="permanent"
        variant={true ? "temporary" : "permanent"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}

        anchor="left"


      >
        <Toolbar />
        <Divider />
        <NestedList />

      </Drawer>
      <Box
        // component={'Dashboard'}
        sx={{
          flexGrow: 1,
          backgroundColor: '#212325',
          //   p: 3 
        }}
      >
        <Toolbar />
        {showMessages && <ShowMessages msgObj={showMessages} setShowMessages={setShowMessages} />}

        {renderDashScreen()}

      </Box>
    </Box>

  );
}


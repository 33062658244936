import React, { useState, useEffect, useRef } from 'react'
import axios from '../Login/axiosConfig'
import PropTypes from 'prop-types';
import { Tabs, Tab, Backdrop, CircularProgress, ListItemText, Divider, TextField, Button, Paper, Stack, Typography, Box, Link, Fab } from '@mui/material';
import { convertDomainToValidURL } from '../Helpers/Helpers';
import RiskSilo from './RiskDemo/RiskSilo';
import RiskChecker from './RiskDemo/RiskChecker';
import RiskPolicies from './RiskDemo/RiskPolicies';
import RiskDomainIntel from './RiskDemo/RiskDomainIntel';
import RiskClientCompany from './RiskDemo/RiskClientCompany'


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3, backgroundColor: "#ffffff", width: "100%" }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const allTabs = [
    '1. Create Client Company',
    '2. Add Content Silo',
    '3. Add Content',
    '4. Add Company Policies',
    '5. Demo SnackRisk Check',

]

const RiskCheckerDashboard = ({ user, isAuthenticated, setShowMessages, initTab }) => {

    const [runRiskData, setRunRiskData] = useState(null)
    const [addPolicyData, setAddPolicyData] = useState(null)
    const [addDomainIntelData, setAddDomainIntelData] = useState(null)
    const [addDeptSiloData, setAddDeptSiloData] = useState(null)
    const [globalDeptId, setGlobalDeptId] = useState(null)
    const [value, setValue] = React.useState(initTab ?? 0);
    const [showProgress, setShowProgress] = useState(false)
    const [clientCompanies, setClientCompanies] = useState(null)
    const [globalClientCompanyId, setGlobalClientCompanyId] = useState("")



    useEffect(() => {
        getClientCompanies()
    }, [])

    useEffect(() => {
        getRiskSilos()
    }, [globalClientCompanyId])

    useEffect(() => {
        getAllPolicies()
        getDomainIntel()
    }, [globalDeptId])



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    // api handlers

    const getRiskSilos = () => {
        if (globalClientCompanyId === null || globalClientCompanyId === "") {
            console.log("bad data", globalClientCompanyId)
            return
        }

        axios.get(`/api/v1/getDeptSilos?clientCompanyId=${globalClientCompanyId}`)
            .then(response => {
                console.log("getRiskSilos response", response.data)
                setAddDeptSiloData(response.data)
                // if (!globalDeptId) {
                setGlobalDeptId(response.data[0].deptId)
                // }


            }).catch(error => {
                // need to figure out way to handle user swiping away from screen and waiting
                console.log("getRiskSilos error:", error)
            })
    }

    const getClientCompanies = () => {
        axios.get('/api/v1/getClientCompanies')
            .then(response => {
                console.log("getClientCompanies response", response.data)
                setClientCompanies(response.data)
                setGlobalClientCompanyId(response.data[0].clientCompanyId)


            }).catch(error => {
                // need to figure out way to handle user swiping away from screen and waiting
                console.log("getClientCompanies error:", error)
            })
    }

    const addClientCompany = (data) => {
        if (!isAuthenticated) {
            setShowMessages({
                alertType: "error",
                title: "Not Logged In",
                message: "Please create an account of log in to add a policy",
            })
            return
        }
        setShowProgress(true)

        console.log("data check:", data)

        axios.post('/api/v1/addClientCompany', data)
            .then(response => {
                getClientCompanies()
                console.log("getClientCompanies:", response.data)
                setShowProgress(false)
            }).catch(error => {
                // need to figure out way to handle user swiping away from screen and waiting
                console.log(error)
                setShowProgress(false)
            })
    }

    const runRisk = (data) => {
        console.log("data check:", data)
        setShowProgress(true)

        axios.post('/api/v1/runRisk', data)
            .then(response => {
                setRunRiskData(response.data)
                console.log("runRisk:", response.data)
                setShowProgress(false)
            }).catch(error => {
                setShowProgress(false)
                // need to figure out way to handle user swiping away from screen and waiting
                console.log(error)
            })
    }



    const addPolicy = (data) => {
        if (!isAuthenticated) {
            setShowMessages({
                alertType: "error",
                title: "Not Logged In",
                message: "Please create an account of log in to add a policy",
            })
            return
        }
        setShowProgress(true)

        console.log("data check:", data)

        axios.post('/api/v1/addPolicy', data)
            .then(response => {
                getAllPolicies(data.deptId)
                console.log("addPolicy:", response.data)
                setShowProgress(false)
            }).catch(error => {
                // need to figure out way to handle user swiping away from screen and waiting
                console.log(error)
                setShowProgress(false)
            })
    }

    const getAllPolicies = () => {
        if (globalClientCompanyId === null || globalClientCompanyId === "") {
            return
        }
        if (globalDeptId === null || globalDeptId === "") {
            return
        }
        console.log("getAllPolicies --> globalDeptId:", globalDeptId, "globalClientCompanyId", globalClientCompanyId)

        axios.get(`/api/v1/getPolicies?deptId=${globalDeptId}&clientCompanyId=${globalClientCompanyId}`)
            .then(response => {
                setAddPolicyData(response.data)
                console.log("getPolicies:", response.data)
            }).catch(error => {
                // need to figure out way to handle user swiping away from screen and waiting
                console.log(error)
            })
    }

    const getDomainIntel = () => {
        console.log("getDomainIntel --> globalDeptId:", globalDeptId, "globalClientCompanyId", globalClientCompanyId)
        if (globalClientCompanyId === null || globalClientCompanyId === "") {
            return
        }
        if (globalDeptId === null || globalDeptId === "") {
            return
        }
        axios.post(`/api/v1/getDomainIntel?deptId=${globalDeptId}&clientCompanyId=${globalClientCompanyId}`)
            .then(response => {
                setAddDomainIntelData(response.data)
                console.log("getDomainIntel:", response.data)
            }).catch(error => {
                // need to figure out way to handle user swiping away from screen and waiting
                console.log(error)
            })
    }

    const addDomainIntel = (data) => {
        console.log("data check:", data)
        if (!isAuthenticated) {
            setShowMessages({
                alertType: "error",
                title: "Not Logged In",
                message: "Please create an account of log in to add domain intelligence",
            })
            return
        }
        setShowProgress(true)

        axios.post('/api/v1/addDomainIntel', data)
            .then(response => {
                getDomainIntel(data.deptId)

                console.log("addDomainIntel:",)
                setShowProgress(false)
            }).catch(error => {
                // need to figure out way to handle user swiping away from screen and waiting
                console.log(error)
                setShowProgress(false)

            })
    }

    const addRiskSilo = (data) => {
        console.log("data addRiskSilo:", data)

        // prep for silos
        let siloArray = data.newRiskSilos.map((e) => {
            return {
                deptSiloName: e,
                clientCompanyId: globalClientCompanyId
            }
        })

        //

        if (!isAuthenticated) {
            setShowMessages({
                alertType: "error",
                title: "Not Logged In",
                message: "Please create an account of log in to add a risk silo",
            })
            return
        }


        setShowProgress(true)
        axios.post('/api/v1/addDeptSilo', siloArray)
            .then(response => {
                getRiskSilos()
                console.log("addRiskSilo:", response.data)
                setShowProgress(false)
            }).catch(error => {
                // need to figure out way to handle user swiping away from screen and waiting
                console.log(error)
                setShowProgress(false)
            })
    }



    return (
        <Box sx={{ width: "100%", backgroundColor: "#ffffff", }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showProgress}

            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box sx={{ backgroundColor: "#ffffff", maxWidth: { xs: 360, sm: 480, md: "100%", lg: "100%", xl: "100%" } }}>
                <Tabs
                    value={value}
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={handleChange}
                    aria-label="risk checker demo"
                >
                    <Tab label={allTabs[0]} {...a11yProps(0)} sx={{ color: "#212325" }} />
                    <Tab label={allTabs[1]} {...a11yProps(1)} sx={{ color: "#212325" }} />
                    <Tab label={allTabs[2]} {...a11yProps(2)} sx={{ color: "#212325" }} />
                    <Tab label={allTabs[3]} {...a11yProps(3)} sx={{ color: "#212325" }} />
                    <Tab label={allTabs[4]} {...a11yProps(4)} sx={{ color: "#212325" }} />
                    {/* <Tab label={allTabs[4]} {...a11yProps(4)} sx={{ color: "#212325" }} /> */}
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <RiskClientCompany fcn={addClientCompany} clientCompanies={clientCompanies} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <RiskSilo fcn={addRiskSilo} data={null} user={user}
                    clientCompanies={clientCompanies}
                    globalClientCompanyId={globalClientCompanyId} setGlobalClientCompanyId={setGlobalClientCompanyId}

                    globalDeptId={globalDeptId} setGlobalDeptId={setGlobalDeptId}
                    addDeptSiloData={addDeptSiloData} />

            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <RiskDomainIntel fcn={addDomainIntel} data={addDomainIntelData}
                    clientCompanies={clientCompanies}
                    globalClientCompanyId={globalClientCompanyId} setGlobalClientCompanyId={setGlobalClientCompanyId}

                    globalDeptId={globalDeptId} setGlobalDeptId={setGlobalDeptId}
                    getDomainIntel={getDomainIntel} user={user} addDeptSiloData={addDeptSiloData} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <RiskPolicies fcn={addPolicy} data={addPolicyData}
                    clientCompanies={clientCompanies}
                    globalClientCompanyId={globalClientCompanyId} setGlobalClientCompanyId={setGlobalClientCompanyId}

                    globalDeptId={globalDeptId} setGlobalDeptId={setGlobalDeptId}
                    getAllPolicies={getAllPolicies} user={user} addDeptSiloData={addDeptSiloData} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
                <RiskChecker fcn={runRisk} data={runRiskData}
                    user={user} addDeptSiloData={addDeptSiloData}
                    clientCompanies={clientCompanies}
                    globalClientCompanyId={globalClientCompanyId} setGlobalClientCompanyId={setGlobalClientCompanyId}
                    globalDeptId={globalDeptId} setGlobalDeptId={setGlobalDeptId}
                />
            </CustomTabPanel>
            
            
            
           

        </Box>
    )

}

export default RiskCheckerDashboard
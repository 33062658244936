import React, { useEffect, useState, useRef } from 'react'
import {
    createTheme,
    responsiveFontSizes,
    ThemeProvider,
} from '@mui/material/styles';
import { Typography, Stack, Button, Box, Chip, Backdrop, CircularProgress } from '@mui/material';
import Footer from '../Components/Footer.js'
import risk_image from '../assets/images/heroCustomer.png'
import fact_check from '../assets/homepage/fact_checking-new.webp'
import ds_side from '../assets/homepage/Homepage_Spot_1_1400.webp'




let theme = createTheme();
theme = responsiveFontSizes(theme);

const RiskAPI = ({ handleScreenChangeClick }) => {
    const scrollRef = useRef(null);

    const [showProgress, setShowProgress] = useState(false)

    const [heroScrollShown, setHeroScrollShown] = useState(false)




    useEffect(() => {
        window.scrollTo(0, 0)
        console.log("riskapi useffect")
        let scrollStatus = localStorage.getItem('heroScrollShown');
        if (scrollStatus) {
            setHeroScrollShown(true)
        }
        return () => {
            setShowProgress(false)
        }

    }, []);





    return (
        <div style={{
            backgroundColor: "#ffffff",
            // width: "390px"
            width: "100%"
        }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showProgress}

            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ThemeProvider theme={theme}>




                {/* DataSnack Risk Mitigation  */}
                <Box sx={{ backgroundColor: "#000000" }}>
                    {/* <Stack direction={"row"}
                        paddingX={2.5}
                        alignItems={"center"}
                        paddingTop={2}
                        justifyContent={"space-between"}>

                        <Button
                            variant="text" onClick={() => {
                                handleScreenChangeClick({
                                    selectedIndex: "HomePage"
                                })

                            }} sx={{ textTransform: "none" }}  >
                            <Stack spacing={1} direction="row" alignItems="center">
                                <ArrowBackIcon sx={{ color: "#ffffff", fontSize: 12, }} />
                                <Typography sx={{ typography: { fontFamily: "Roboto", fontWeight: 400 }, fontSize: 12, color: "#ffffff" }} fontStyle={"normal"}>
                                    {`Home`}
                                </Typography>
                            </Stack>
                        </Button>


                    </Stack> */}
                    <Stack paddingX={window.$isMobile ? 1.5 : 10}
                      
                        paddingY={10}
                        

                        spacing={5}>
                        {/* section title */}
                        <Stack direction={"column"}
                            spacing={3}
                            paddingBottom={"5%"}
                            alignItems={"center"}>
                            <Typography
                                variant={'h3'}

                                sx={{

                                    typography: { fontFamily: "Roboto", fontWeight: 300 },
                                    textAlign: "center",
                                    lineHeight: '105%', color: "#ffffff"
                                }} >
                                SnackRisk: AI Factual Risk & Policy Violation Mitigation
                            </Typography>


                        </Stack>



                        {/* Mitigating Risks */}
                        <Stack
                            sx={{ backgroundColor: "#ffffff", borderRadius: 2.5 }}>


                            <Stack direction={window.$isMobile ? "column" : "row"} width={"100%"}
                                paddingX={2.5}
                                paddingY={7.5}
                                alignItems={"center"}
                                justifyContent={"space-between"}

                                spacing={5}

                            >
                                <Stack width={"100%"}>

                                {window.$isMobile && <Stack width={"35%"} paddingBottom={5}>
                                        <Chip label="Risks" sx={{ color: "#ffffff", backgroundColor: "#66a8da" }} />
                                    </Stack>}

                                    <Typography
                                        variant={'h4'}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 900 },
                                            textAlign: "left",
                                            lineHeight: '105%', color: "#00000"
                                        }} >
                                        Mitigating Risks: Understanding and Detecting Hallucinations in Generative AI Content
                                    </Typography>
                                    <Typography
                                        marginTop={2}
                                        variant={'h6'}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 400 },
                                            textAlign: "left",
                                            lineHeight: '105%', color: "#00000"
                                        }} >

                                        Generative AI models, such as large language models (LLMs), have revolutionized the way we create content, but they also present a unique challenge in ensuring the reliability and trustworthiness of the generated content. One of the key challenges is the occurrence of "hallucinations," which refer to instances where the generated content deviates from factual accuracy or fails to align with the given context or instructions. Detecting and mitigating hallucinations is crucial for maintaining the integrity and accuracy of AI-generated content.
                                        <br /><br />DataSnack's real-time risk analysis engine can highlight problems instantly, enabling organizations to rectify issues before they impact decision-making and compliance.

                                    </Typography>
                                    <Stack marginTop={2}>
                                        <Button
                                            variant='outlined'
                                            onClick={() => {
                                                handleScreenChangeClick({
                                                    selectedIndex: "Contact"

                                                })
                                            }} sx={{ borderColor: "#000000 ", width: window.$isMobile ? "100%" : "300px", height: "45px" }}>
                                            <Typography
                                                variant={'h7'}
                                                sx={{

                                                    textTransform: "none",
                                                    typography: { fontFamily: "Roboto", fontWeight: 700 },

                                                    color: "#000000"
                                                }} >
                                                Get a demo

                                            </Typography>
                                        </Button>
                                    </Stack>

                                </Stack>

                                <Stack width={"100%"} alignItems={"flex-start"}>
                                    <img src={fact_check} alt="Location Image" style={{ width: "100%" }} />

                                    {/* <Typography
                                       
                                        variant={'h6'}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 400 },
                                            textAlign: "left",
                                            lineHeight: '105%', color: "#00000"
                                        }} >


                                        <b>Factual Hallucinations:</b> Occur when there is a discrepancy between the generated content and real-world facts, resulting in factual inconsistency or fabrication.
                                        <br /><br />Detection strategies include retrieving external facts, utilizing uncertainty estimation, and employing tools like DataSnack's SnackRisk framework for facts detection.
                                        <br /><br />
                                        <b>Faithfulness Hallucinations:</b> Arise when there is a divergence of generated content from user instructions or context, as well as self-consistency within the generated content.
                                        <br /><br />Detection strategies involve fact-based metrics, classifier-based metrics, question-answering based metrics, uncertainty estimation, and prompting-based metrics.
                                        <br /><br />
                                        <b>Company Policy Violations:</b> LLM-generated content may inadvertently violate company policies, leading to potential legal, compliance, or reputational risks for organizations.

                                    </Typography> */}

                                </Stack>

                            </Stack>
                        </Stack>

                        {/* Illustrative Examples */}
                        <Stack
                            sx={{ backgroundColor: "#ffffff", borderRadius: 2.5 }}>


                            <Stack direction={window.$isMobile ? "column" : "row"} width={"100%"}
                                paddingX={2.5}
                                paddingY={7.5}
                                justifyContent={"space-between"}
                                spacing={window.$isMobile ? 1 : 5}

                            >
                                <Stack width={"100%"}>
                                {window.$isMobile && <Stack width={"35%"} paddingBottom={5}>
                                        <Chip label="Examples" sx={{ color: "#ffffff", backgroundColor: "#66a8da" }} />
                                    </Stack>}

                                    <Typography
                                        variant={'h4'}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 900 },
                                            textAlign: "left",
                                            lineHeight: '105%', color: "#00000"
                                        }} >
                                        Illustrative Examples of Hallucinations and Policy Violations in AI-Generated Content
                                    </Typography>
                                   {!window.$isMobile && <Typography
                                        marginTop={2}
                                        variant={'h6'}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 400 },
                                            textAlign: "left",
                                            lineHeight: '105%', color: "#00000"
                                        }} >
DataSnack offers a robust solution to mitigate these issues by providing real-time risk analysis and integrating customizable policies into its knowledge graph. By leveraging DataSnack, organizations can ensure that AI-generated content aligns with factual information and user instructions, reducing the risk of factual and faithfulness hallucinations. Additionally, the platform's real-time risk analysis engine highlights discrepancies and policy violations instantly, allowing organizations to rectify issues before they impact decision-making and compliance, thereby minimizing the risk of policy violations in AI-generated content.<br/><br/>
                                    </Typography>}
                                    {!window.$isMobile && <Stack marginTop={2}>
                                        <Button
                                            variant='outlined'
                                            onClick={() => {
                                                handleScreenChangeClick({
                                                    selectedIndex: "Contact"

                                                })
                                            }} sx={{ borderColor: "#000000 ", width: window.$isMobile ? "100%" : "300px", height: "45px" }}>
                                            <Typography
                                                variant={'h7'}
                                                sx={{

                                                    textTransform: "none",
                                                    typography: { fontFamily: "Roboto", fontWeight: 700 },

                                                    color: "#000000"
                                                }} >
                                                Get a demo

                                            </Typography>
                                        </Button>
                                    </Stack>}

                                </Stack>

                                <Stack width={"100%"} >
                                    <Typography
                                        marginTop={2}
                                        variant={'h6'}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 400 },
                                            textAlign: "left",
                                            lineHeight: '105%', color: "#00000"
                                        }} >

                                        <b>Factual Hallucinations:</b> An LLM generates a news article claiming that a celebrity has won an award that they never actually received. This discrepancy between the generated content and the real-world facts constitutes a factual hallucination, as it presents fabricated information that does not align with the truth.
                                        <br /><br />
                                        <b>Faithfulness Hallucinations:</b> In response to a user prompt to summarize a scientific paper, an LLM generates a summary that misinterprets the core findings of the paper, deviating from the user's instructions and failing to faithfully represent the content. This divergence from the given context and user instructions represents a faithfulness hallucination.
                                        <br /><br />
                                        <b>Company Policy Violations:</b> An organization uses an LLM to generate marketing materials, and the AI-produced content inadvertently includes language that violates the company's advertising standards or privacy policies. This violation of internal policies poses legal and reputational risks for the organization, highlighting the importance of ensuring AI-generated content aligns with internal guidelines and regulations.

                                    </Typography>


                                </Stack>

                            </Stack>
                        </Stack>
                    </Stack>
                </Box>

                {/* DataSnack's SnackRisk API Use Cases */}
                <Box sx={{ backgroundColor: "#ffffff" }}>

                    <Stack direction={window.$isMobile ? "column" : "row"} width={"100%"}

                        justifyContent={"space-between"}
                        spacing={5}
                        alignItems={"center"}
                        paddingX={window.$isMobile ? 2.5 : 10}
                        paddingY={10}

                    >

                        <Stack width={"100%"}>
                            <Typography
                                // paddingRight={50}
                                // paddingX={window.$isMobile ? 2.5 : 10}
                                variant={'h3'}
                                sx={{

                                    typography: { fontFamily: "Roboto", fontWeight: 900 },
                                    textAlign: "left",
                                    lineHeight: '105%', color: "#000000"
                                }} >
                                DataSnack's SnackRisk API Use Cases
                            </Typography>
                            <Typography
                                marginTop={2}
                                variant={'h6'}
                                sx={{

                                    typography: { fontFamily: "Roboto", fontWeight: 400 },
                                    textAlign: "left",
                                    lineHeight: '105%', color: "#000000"
                                }} >
                                In various industries, organizations face the challenge of ensuring the reliability and trustworthiness of AI-generated content. DataSnack's Risk API offers a versatile solution for mitigating the risks associated with generative AI hallucinations, making it essential for a wide range of use cases across different sectors.
                                <ul>
                                    <li><b>Compliance Monitoring</b>: An organization in a highly regulated industry such as finance or healthcare can use DataSnack's Risk API to ensure that AI-generated content adheres to industry-specific regulations and compliance standards, mitigating the risk of non-compliance and potential legal issues.</li>
                                    <br /><li><b>Content Quality Assurance</b>: Media and publishing organizations can utilize DataSnack's Risk API to verify the accuracy and reliability of AI-generated content, ensuring that published material aligns with factual information and minimizes the risk of misinformation or factual inconsistencies.</li>
                                    <br /><li><b>Customer Support Automation</b>: Companies deploying AI-powered chatbots or virtual assistants for customer support can integrate DataSnack's Risk API to validate the responses provided by these AI systems, minimizing the risk of faithfulness hallucinations and ensuring that customer interactions are consistent with company policies and guidelines.</li>
                                </ul>
                            </Typography>

                        </Stack>

                        <Stack width={"100%"} alignItems={"flex-start"}>
                            <img src={risk_image} alt="Location Image" style={{ width: "100%" }} />


                        </Stack>


                    </Stack>

                </Box>




                {/* Why Choose DataSnack? */}
                <Box sx={{ backgroundColor: "#f4f3ec" }}>

                    <Stack direction={window.$isMobile ? "column" : "row"} width={"100%"}
                            alignItems={"center"}

                        justifyContent={"space-between"}
                        spacing={5}
                        paddingX={window.$isMobile ? 2.5 : 10}
                        paddingY={10}

                    >

                        <Stack width={"100%"}>
                            <Typography
                                // paddingRight={50}
                                // paddingX={window.$isMobile ? 2.5 : 10}
                                variant={'h3'}
                                sx={{

                                    typography: { fontFamily: "Roboto", fontWeight: 900 },
                                    textAlign: "left",
                                    lineHeight: '105%', color: "#000000"
                                }} >
                                Why Choose DataSnack's SnackRisk API?
                            </Typography>
                            <Typography
                                marginTop={2}
                                variant={'h6'}
                                sx={{

                                    typography: { fontFamily: "Roboto", fontWeight: 400 },
                                    textAlign: "left",
                                    lineHeight: '105%', color: "#000000"
                                }} >
                                DataSnack offers a compelling solution for organizations seeking to mitigate the risks of generative AI hallucinations and policy violations, providing a robust foundation for ensuring the integrity and reliability of their data and insights.
                                <ul>
                                    <li><b>Mitigates Risk</b>: Mitigates risks associated with generative AI hallucinations and policy violations, ensuring the integrity and reliability of organizational data and insights.</li>
                                    <br /><li><b>Real-Time</b>: Empowers organizations with real-time risk analysis and customizable policy creation to rectify issues before they impact decision-making and compliance.</li>
                                    <br /><li><b>Fosters Trust</b>: Proactively ensures timely, accurate responses that align with internal guidelines and regulatory standards, fostering trust and operational efficiency.</li>
                                </ul>
                                By leveraging DataSnack's real-time risk analysis, customizable policies, and proactive rectification mechanisms, organizations can confidently navigate the complexities of AI-generated content, ensuring timely, accurate responses that align with internal guidelines and regulatory standards, ultimately fostering trust and operational efficiency.
                            </Typography>

                        </Stack>

                        <Stack width={"100%"} alignItems={"center"}>
                            <img src={ds_side} alt="Location Image" style={{ width: "45%" }} />


                        </Stack>

                    </Stack>

                </Box>

                {/* DataSnack: Safeguarding Against Hallucinations and Policy Violations in AI-Generated Content */}
                {/* <Box sx={{ backgroundColor: "#000000", }}>
                    <Stack paddingX={window.$isMobile ? 2.5 : 10}
                        paddingY={10}

                    >

                        <Stack direction={"column"}
                            paddingBottom={7.5} >
                            <Typography
                                paddingX={window.$isMobile ? 2.5 : 10}
                                variant={'h3'}
                                sx={{

                                    typography: { fontFamily: "Roboto", fontWeight: 300 },
                                    textAlign: "center",
                                    lineHeight: '105%', color: "#ffffff"
                                }} >
                                DataSnack: Safeguarding Against Hallucinations and Policy Violations in AI-Generated Content
                            </Typography>

                        </Stack>



                    </Stack>
                </Box> */}


                {/* Simple, transparent pricing */}
                <Box sx={{ backgroundColor: "#ffff66" }}>
                    <Stack paddingX={window.$isMobile ? 2.5 : 20}
                        paddingY={10} spacing={5}>



                        <Stack
                            sx={{ backgroundColor: "#ffffff" }}>


                            <Stack direction={window.$isMobile ? "column" : "row"} width={"100%"}
                                paddingX={2.5}
                                paddingY={7.5}
                                justifyContent={"center"}
                                alignItems={"center"}
                                spacing={window.$isMobile ? 5 : 2}
                                sx={{ border: 1, borderColor: "#212325" }}
                            >
                                <Stack width={"100%"} direction={"column"} spacing={5} alignItems={"center"}>

                                    <Typography
                                        variant={'h3'}
                                        paddingLeft={window.$isMobile ? 0 : 7.5}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 500 },
                                            textAlign: window.$isMobile ? "center" : "left", fontStyle: "italic",
                                            lineHeight: '105%', color: "#00000"
                                        }} >
                                        Simple, transparent pricing


                                    </Typography>


                                </Stack>




                                <Stack width={"100%"} direction={"column"} spacing={3} alignItems={"center"} >
                                    <Typography
                                        marginTop={2}
                                        variant={'h2'}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 900 },
                                            textAlign: "center",
                                            lineHeight: '105%', color: "#00000"
                                        }} >
                                        Starting at <br />$49 / mo.


                                    </Typography>
                                    <Button
                                        variant='outlined'
                                        onClick={() => {
                                            handleScreenChangeClick({
                                                selectedIndex: "Contact"

                                            })
                                        }} sx={{ borderColor: "#000000 ", width: window.$isMobile ? "100%" : "300px", height: "45px" }}>
                                        <Typography
                                            variant={'h7'}
                                            sx={{

                                                textTransform: "none",
                                                typography: { fontFamily: "Roboto", fontWeight: 700 },

                                                color: "#000000"
                                            }} >
                                            Contact DataSnack

                                        </Typography>
                                    </Button>

                                </Stack>

                            </Stack>
                        </Stack>


                    </Stack>
                </Box>

                {/* Implement DataSnack's AI Chatbot today to elevate engagement and differentiate your business in the industry. */}
                <Box sx={{

                    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.05'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
                    backgroundColor: "#212325",


                }}>
                    <Stack paddingX={2.5}
                        paddingY={20} direction={"column"} width={"100%"}
                        spacing={2}
                        alignItems={"center"}

                    >

                        <Typography
                            variant={'h3'}
                            paddingX={window.$isMobile ? 0 : 10}
                            sx={{

                                typography: { fontFamily: "Roboto", fontWeight: 300 },
                                textAlign: "center",
                                lineHeight: '105%', color: "#ffffff"
                            }} >
                            Safeguard your organization with <b>confidence, reliability, and operational efficiency.</b>
                        </Typography>
                        <Button
                            variant='outlined'
                            onClick={() => {
                                handleScreenChangeClick({
                                    selectedIndex: "Contact"

                                })
                            }} sx={{ borderColor: "#ffffff ", width: window.$isMobile ? "100%" : "200px", height: "45px" }}>
                            <Typography
                                variant={'h7'}
                                sx={{

                                    textTransform: "none",
                                    typography: { fontFamily: "Roboto", fontWeight: 700 },

                                    color: "#ffffff"
                                }} >
                                Contact DataSnack

                            </Typography>
                        </Button>
                    </Stack>


                </Box>
                <Footer handleScreenChangeClick={handleScreenChangeClick} backgroundColor={"#000000"} />
            </ThemeProvider>
        </div >
    )

}

export default RiskAPI






import React, { useState, useEffect, useRef } from 'react'
import axios from '../Login/axiosConfig'
import { ListItem, ListItemText, Divider, TextField, Button, Paper, Stack, Typography, Box, Link, Fab } from '@mui/material';
import { convertDomainToValidURL } from '../Helpers/Helpers';
// import homepageHolder from '../assets/images/dataSnackHeroTop.png'

import homepageHolder from '../assets/images/genAIFlow3.png'


const HelpWithBot = ({ handleScreenChangeClick, setShowMessages }) => {

    const [name, setName] = useState("")
    const [company, setCompany] = useState("")
    const [note, setNote] = useState("")
    const [email, setEmail] = useState("")

    useEffect(() => {
        window.scrollTo(0, 0)

    }, []);


    const handleSubmit = () => {

        const register = {
            fullName: name,
            companyName: company,
            note: note,
            email: email,

        }


        axios.post('/api/v1/sendNote', register)
            .then(response => {
                setShowMessages({
                    alertType: "success",
                    title: "Success",
                    message: "Message sent.",
                })
                console.log(response.data)
                let toSend = response.data
                toSend.selectedIndex = "Dashboard"
                toSend.updateDash = true
                handleScreenChangeClick(toSend)

            }).catch(error => {

                console.log("error", error)
                setShowMessages({
                    alertType: "error",
                    title: "Error sending message",
                    message: "Please try back at a later time.",
                })

            });
    }

    return (
        <>
            <div style={{
                backgroundColor: "#F5F7F8",
                minHeight: "1024px",
                // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23262f71' fill-opacity='0.05'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
            }}>

                <div style={{
                    display: "flex", justifyContent: "space-between", flexDirection: "column",
                    paddingLeft: "5%",
                    paddingRight: "5%",
                    paddingTop: "15px",
                    paddingBottom: "10%",

                }}>
                    {/* Hero How DataSnack works */}
                    {!window.$isMobile && <Box sx={{ backgroundColor: window.$isMobile ? "#F5F7F8" : "#212325", width: "100%" }}>

                        <Stack
                            paddingLeft={window.$isMobile ? "0%" : "5%"}
                            paddingRight={window.$isMobile ? "0%" : "5%"}
                            paddingTop={window.$isMobile ? "50px" : "5%"}
                            paddingBottom={window.$isMobile ? "5%" : "5%"}
                            alignItems={"center"}
                            spacing={window.$isMobile ? 0 : 10}

                            direction={window.$isMobile ? "column" : "row"} justifyContent={"space-between"} >
                            <Stack spacing={0}

                                direction={"column"} width={window.$isMobile ? "100%" : "100%"}>
                                <Typography
                                    paddingBottom={window.$isMobile ? 1 : 1}
                                    sx={{
                                        textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 900 },
                                        fontSize: window.$isMobile ? 42 : 78, lineHeight: '105%',
                                        color: window.$isMobile ? "#37383a" : "#ffffff"
                                    }} fontStyle={"normal"}>
                                    Let Us Help Set Up Your <span style={{ color: "#499dd7" }}>AI Chatbot</span>
                                </Typography>
                                <Typography
                                    paddingBottom={window.$isMobile ? 4 : 4} paddingRight={window.$isMobile ? "10%" : "15%"}
                                    sx={{
                                        textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: window.$isMobile ? 600 : 600 },
                                        fontSize: window.$isMobile ? 16 : 20, lineHeight: '105%', color: window.$isMobile ? "#37383a" : "#ffffff"
                                    }} fontStyle={"normal"}>

                                    Empower Your Customer Support with Advanced AI Chatbot Solutions
                                </Typography>

                                {!window.$isMobile && <Typography
                                    paddingBottom={window.$isMobile ? 4 : 4} paddingRight={window.$isMobile ? "10%" : "15%"}
                                    sx={{
                                        textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: window.$isMobile ? 400 : 400 },
                                        fontSize: window.$isMobile ? 16 : 16, lineHeight: '105%', color: "#ffffff"
                                    }} fontStyle={"normal"}>

                                    Looking to streamline your customer service experience with AI Chatbots? DataSnack offers implementation expertise that covers mapping your existing customer service workflow, automating responses with AI, and managing incoming communications.

                                </Typography>}


                            </Stack>

                            {!window.$isMobile && <Stack width={window.$isMobile ? "90%" : "100%"}>
                                <img src={homepageHolder} alt="Location Image" style={{ width: "100%" }} />

                            </Stack>}


                        </Stack>



                    </Box>}
                    <Stack width={"100%"} direction="column" marginTop={5} >
                        <Typography marginTop={0} sx={{ fontSize: 42, typography: { fontFamily: "Roboto", fontWeight: 900 }, lineHeight: '90%', color: "#37383a" }} fontStyle={"normal"} component="div">
                            Get In Touch!
                        </Typography>
                        {window.$isMobile && <Typography
                            paddingBottom={window.$isMobile ? 4 : 4} paddingRight={window.$isMobile ? "10%" : "15%"}
                            paddingTop={1}
                            sx={{
                                textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: window.$isMobile ? 400 : 400 },
                                fontSize: window.$isMobile ? 16 : 20, lineHeight: '105%', color: window.$isMobile ? "#37383a" : "#ffffff"
                            }} fontStyle={"normal"}>

                            Empower Your Customer Support with Advanced GenAI Bot Solutions
                        </Typography>}

                        <Stack marginTop={3}>

                            <TextField
                                autoFocus={false}
                                margin="dense"
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                                id="name"
                                label="Name"
                                type="text"
                                fullWidth
                                value={name}
                                variant="standard"
                            />

                            <TextField
                                autoFocus={false}
                                margin="dense"
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                id="email"
                                label="Email"
                                type="email"
                                fullWidth
                                value={email}
                                variant="standard"
                            />
                            <TextField
                                autoFocus={false}
                                margin="dense"
                                onChange={(e) => {
                                    setCompany(e.target.value)
                                }}
                                id="company"
                                label="Company"
                                type="text"
                                value={company}
                                fullWidth
                                variant="standard"
                            />
                            <TextField
                                autoFocus={false}
                                margin="dense"
                                onChange={(e) => {
                                    // add validation
                                    setNote(e.target.value)
                                }}
                                multiline
                                id="note"
                                label="Message"
                                type="text"
                                fullWidth
                                value={note}
                                variant="standard"
                            />




                            <Stack width={"100%"} direction="row" spacing={2} marginTop={2} justifyContent={"flex-end"} >

                                <Button onClick={() => {
                                    let toSend = {
                                        selectedIndex: "Dashboard",
                                    }
                                    handleScreenChangeClick(toSend)
                                }}>Cancel</Button>
                                <Button onClick={handleSubmit} >Send</Button>
                            </Stack>

                        </Stack>

                    </Stack>
                </div>

            </div>
        </>
    )

}

export default HelpWithBot
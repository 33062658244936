import React, { useEffect, useState } from 'react'
import {
    createTheme,
    responsiveFontSizes,
    ThemeProvider,
} from '@mui/material/styles';
import { Typography, Stack, Button, Box, Chip, Backdrop, CircularProgress, Divider } from '@mui/material';


import Footer from '../Components/Footer.js'


import home_bot from '../assets/homepage/green-ipad.png'
import chatbot_bg from '../assets/homepage/chatbot_bg.webp'
import dental from '../assets/homepage/ds_analyze.png'
import chatbotty from '../assets/homepage/narrative.png'
import noCode from '../assets/homepage/setup-flow.png'
import ds_side from '../assets/homepage/Homepage_Spot_1_1400.webp'




let theme = createTheme();
theme = responsiveFontSizes(theme);

const HomePageChat = ({ handleScreenChangeClick }) => {
    // const scrollRef = useRef(null);

    const [showProgress, setShowProgress] = useState(false)

    const [heroScrollShown, setHeroScrollShown] = useState(false)




    useEffect(() => {
        window.scrollTo(0, 0)
        console.log("homepage useffect")
        let scrollStatus = localStorage.getItem('heroScrollShown');
        if (scrollStatus) {
            setHeroScrollShown(true)
        }
        return () => {
            setShowProgress(false)
        }

    }, []);





    return (
        <div style={{
            backgroundColor: "#ffffff",
            // width: "390px"
            width: "100%"
        }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showProgress}

            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ThemeProvider theme={theme}>

                {/* Introducing the World's First AI Chatbot with Built-In Hallucination Prevention */}
                <Box sx={{

                    // backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 25%,rgba(0,0,0,1) 100%), url(${hero_bg})`,
                    backgroundImage: `linear-gradient(to bottom, #66a8da 0%, #006ec2 100%)`,

                    // backgroundRepeat: "no-repeat",
                    // backgroundSize: "cover",
                    // backgroundPosition: "center",
                    // height: window.$isMobile ? 1100 : 680,
                    // backgroundColor: "#66a8da"

                }}>
                    <Stack>
                        <Stack
                            paddingX={window.$isMobile ? 2.5 : 5}
                            paddingY={10}
                            alignItems={"center"}
                            spacing={window.$isMobile ? 0 : 10}

                            direction={window.$isMobile ? "column" : "row"} width={"100%"} justifyContent={"space-between"} >
                            <Stack spacing={0} direction={"column"} width={window.$isMobile ? "100%" : "100%"}>
                                <Typography

                                    paddingBottom={window.$isMobile ? 1 : 1}
                                    variant={window.$isMobile ? 'h1' : 'h2'}
                                    sx={{

                                        textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 900 },
                                        lineHeight: '90%', color: "#ffffff"
                                    }} fontStyle={"normal"}>
                                    DataSnack's All-in-One Employee Chatbot Solution: <span style={{ color: "#000000" }}>Manage Risks, Integrate RAG, and Deploy with Ease</span>
                                </Typography>
                                <Typography
                                    variant={'h5'}
                                    paddingBottom={window.$isMobile ? 4 : 4} paddingRight={window.$isMobile ? "10%" : "15%"}
                                    sx={{

                                        textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: window.$isMobile ? 600 : 600 },
                                        lineHeight: '105%', color: "#ffffff"
                                    }} fontStyle={"normal"}>

                                    Drive Employee Efficiency and Mitigate Risks with DataSnack's Comprehensive Chatbot Solution
                                </Typography>

                                <Typography
                                    variant={'body1'}
                                    paddingBottom={window.$isMobile ? 4 : 4} paddingRight={window.$isMobile ? "10%" : "15%"}
                                    sx={{

                                        textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: window.$isMobile ? 400 : 400 },
                                        lineHeight: '120%', color: "#ffffff"
                                    }} fontStyle={"normal"}>
                                    {window.$isMobile ? "" : "DataSnack's employee chatbot is the ultimate all-in-one solution for managing employee risks and enhancing productivity. With built-in hallucination prevention, Retrieval-Augmented Generation (RAG), and seamless deployment options, our chatbot ensures accurate and secure interactions. Deploy it effortlessly as a tag on your internal website or as an extension, and experience a hassle-free setup managed entirely by our expert team.  "}
                                </Typography>

                                {window.$isMobile && <Stack paddingBottom={10} alignSelf={"center"} width={"100%"}>
                                    <img src={home_bot} alt="Location Image" style={{ width: "100%" }} />

                                </Stack>}

                                <Stack direction={window.$isMobile ? "column-reverse" : "row"} spacing={2} >


                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            handleScreenChangeClick({
                                                selectedIndex: "Contact"

                                            })
                                        }} sx={{ borderColor: "#ffffff", width: window.$isMobile ? "100%" : "200px", height: "45px" }}>
                                        <Typography
                                            variant={'body1'}
                                            sx={{

                                                textTransform: "none",
                                                typography: { fontFamily: "Roboto", fontWeight: 700 },

                                                color: "#ffffff"
                                            }} >
                                            Contact DataSnack

                                        </Typography>
                                    </Button>


                                </Stack>

                            </Stack>

                            {!window.$isMobile && <Stack width={"100%"}>
                                <img src={home_bot} alt="Location Image" style={{ width: "100%" }} />

                            </Stack>}


                        </Stack>

                        {window.$isMobile && <Divider sx={{ alignSelf: "center", backgroundColor: "#66a8da", opacity: 0.5, width: "75%", borderWidth: 0.5 }} variant="middle" />}


                    </Stack>
                </Box >


                {/* Transform Your Business with DataSnack's AI Chatbot */}
                <Box sx={{ backgroundColor: "#000000" }}>
                    <Stack paddingX={window.$isMobile ? 1.5 : 10}
                        paddingY={10} spacing={5}>
                        {/* section title */}
                        <Stack direction={"column"}
                            paddingBottom={"5%"}
                            alignItems={"center"}>
                            <Typography
                                variant={'h3'}

                                sx={{

                                    typography: { fontFamily: "Roboto", fontWeight: 300 },
                                    textAlign: "center",
                                    lineHeight: '105%', color: "#ffffff"
                                }} >
                                Transform Your Organization with DataSnack's AI Chatbot
                            </Typography>

                        </Stack>



                        {/* Drive Efficiency, Boost Revenue, and Deliver Unmatched 24/7 Support*/}
                        <Stack
                            sx={{ backgroundColor: "#ffffff", borderRadius: 2.5 }}>


                            <Stack direction={window.$isMobile ? "column" : "row"} width={"100%"}
                                paddingX={2.5}
                                paddingY={7.5}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                spacing={5}

                            >
                                <Stack width={"100%"}>
                                    <Stack width={"25%"} paddingBottom={5}>
                                        <Chip label="Insights" sx={{ color: "#ffffff", backgroundColor: "#66a8da" }} />
                                    </Stack>


                                    <Typography
                                        variant={'h4'}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 900 },
                                            textAlign: "left",
                                            lineHeight: '105%', color: "#00000"
                                        }} >
                                        Effortlessly Manage Employee Interactions and Mitigate Risks

                                    </Typography>
                                    <Typography
                                        marginTop={2}
                                        variant={'body1'}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 400 },
                                            textAlign: "left",
                                            lineHeight: '105%', color: "#00000"
                                        }} >

                                        Leverage DataSnack's advanced AI chatbot capabilities to streamline employee interactions, manage risks, and boost operational efficiency.
                                        <br /><br />

                                        <b>Hallucination Prevention:</b> Our AI chatbot ensures every interaction is accurate and appropriate, mitigating risks associated with misinformation.
                                        <br /><br />
                                        <b>Retrieval-Augmented Generation (RAG):</b> Integrate RAG to provide employees with the most relevant and up-to-date information, enhancing their productivity and decision-making.
                                        <br /><br />
                                        <b>Seamless Deployment:</b> Easily deploy the chatbot as a tag on your internal website or as an extension, ensuring quick and efficient integration.

                                    </Typography>

                                </Stack>

                                <Stack width={"100%"} alignItems={"center"} paddingTop={window.$isMobile ? 2 : 10}>

                                    {!window.$isMobile && <img src={chatbot_bg} alt="Location Image" style={{ width: "100%", position: "relative" }} />}
                                    <img src={chatbotty} alt="Location Image" style={{ width: window.$isMobile ? "100%" : "25%", position: window.$isMobile ? "relative" : "absolute", }} />


                                </Stack>

                            </Stack>
                        </Stack>

                         {/* Key Features */}
                         <Stack
                            sx={{ backgroundColor: "#ffffff", borderRadius: 2.5 }}>


                            <Stack direction={window.$isMobile ? "column" : "row"} width={"100%"}
                                paddingX={2.5}
                                paddingY={7.5}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                spacing={5}

                            >
                                <Stack width={"100%"}>
                                    <Stack width={"25%"} paddingBottom={5}>
                                        <Chip label="Insights" sx={{ color: "#ffffff", backgroundColor: "#66a8da" }} />
                                    </Stack>


                                    <Typography
                                        variant={'h4'}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 900 },
                                            textAlign: "left",
                                            lineHeight: '105%', color: "#00000"
                                        }} >
                                        Key Features of DataSnack's AI Chatbot

                                    </Typography>
                                    <Typography
                                        marginTop={2}
                                        variant={'body1'}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 400 },
                                            textAlign: "left",
                                            lineHeight: '105%', color: "#00000"
                                        }} >

                                      

                                        <b>Risk Management:</b> With the DataSnack Hallucination Prevention Module, ensure that your AI chatbot provides reliable and compliant responses, safeguarding your organization's reputation.  
                                        <br /><br />
                                        <b>Continuous Updates:</b> DataSnack continually sources domain-specific information to keep your AI responses current, eliminating the need for manual updates.  
                                        <br /><br />
                                        <b>Customizable Policies:</b> Integrate customizable policies to ensure that AI-generated content aligns with internal guidelines and compliance standards. 
                                        <br /><br />
                                        <b>Proactive Solutions:</b> Our real-time risk analysis engine highlights discrepancies instantly, allowing for immediate rectification before they impact operations.   

                                    </Typography>

                                </Stack>

                                <Stack width={"100%"} alignItems={"center"} paddingTop={window.$isMobile ? 2 : 10}>

                                    {!window.$isMobile && <img src={chatbot_bg} alt="Location Image" style={{ width: "100%", position: "relative" }} />}
                                    <img src={chatbotty} alt="Location Image" style={{ width: window.$isMobile ? "100%" : "25%", position: window.$isMobile ? "relative" : "absolute", }} />


                                </Stack>

                            </Stack>
                        </Stack>

                        {/* Transform Your Customer Interactions */}
                        <Stack
                            sx={{ backgroundColor: "#ffffff", borderRadius: 2.5 }}>


                            <Stack direction={window.$isMobile ? "column" : "row"} width={"100%"}
                                paddingX={2.5}
                                paddingY={7.5}
                                justifyContent={"space-between"}
                                spacing={5}

                            >
                                <Stack width={"100%"}>
                                    <Stack width={"40%"} paddingBottom={5}>
                                        <Chip label="Use Cases" sx={{ color: "#ffffff", backgroundColor: "#66a8da" }} />
                                    </Stack>
                                    <Typography
                                        variant={'h4'}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 900 },
                                            textAlign: "left",
                                            lineHeight: '105%', color: "#00000"
                                        }} >
                                        Example Use Cases
                                    </Typography>
                                    <Typography
                                        marginTop={2}
                                        variant={'body1'}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 400 },
                                            textAlign: "left",
                                            lineHeight: '105%', color: "#00000"
                                        }} >
                                       Unlock the potential of DataSnack's AI chatbot to transform employee interactions, driving efficiency, enhancing satisfaction, and fostering deeper engagement. By leveraging customizable policies and real-time risk analysis, you'll gain invaluable insights that fuel operational growth. 
                                        <br /><br />
                                        <b>HR Inquiries:</b> Employees can interact with the DataSnack AI chatbot to inquire about their benefits, leave policies, and other HR-related questions, receiving accurate and compliant responses instantly.  
                                        <br /><br />
                                        <b>IT Support:</b> The chatbot can handle common IT support requests, providing solutions based on the latest technical knowledge and reducing the burden on IT staff.  
                                        <br /><br />
                                        <b>Training and Development:</b> Employees seeking information on training programs can receive detailed guidance and resources, ensuring they have access to the most relevant and up-to-date information.  
                                    </Typography>

                                </Stack>

                                <Stack width={"100%"} alignItems={"center"} paddingTop={window.$isMobile ? 2 : 10}>
                                    <img src={dental} alt="Location Image" style={{ width: window.$isMobile ? "100%" : "80%" }} />


                                </Stack>

                            </Stack>
                        </Stack>
                    </Stack>
                </Box>

                {/* No Technical Knowledge Needed */}
                <Box sx={{ backgroundColor: "#000000", }}>
                    <Stack paddingX={window.$isMobile ? 2.5 : 10}
                        paddingY={10}
                        paddingTop={-5}
                    >

                        <Stack direction={"column"}>
                            <Typography
                                paddingX={window.$isMobile ? 2.5 : 10}
                                variant={'h3'}
                                sx={{

                                    typography: { fontFamily: "Roboto", fontWeight: 300 },
                                    textAlign: "center",
                                    lineHeight: '105%', color: "#ffffff"
                                }} >
                                No Technical Knowledge Needed 
                            </Typography>

                        </Stack>


                        <Stack direction={window.$isMobile ? "column" : "row"} width={"100%"}
                            paddingTop={5}
                            justifyContent={"space-between"}
                            spacing={5}
                            alignItems={"center"}
                        >
                            <Stack width={"100%"}>

                                <Typography
                                    marginTop={2}
                                    variant={'h6'}
                                    sx={{

                                        typography: { fontFamily: "Roboto", fontWeight: 400 },
                                        textAlign: "left",
                                        lineHeight: '105%', color: "#ffffff"
                                    }} >

We handle the entire process, seamlessly integrating our solution into your organization.  
<br /> <br />

                                    <b>Implementation</b> 
                                    <br /> <br />
                                    <b>Testing</b>
                                    <br /> <br />
                                    <b>Deployment</b> 
                                    <br /> <br />
                                    <b>Monitoring</b> 
                                    <br /> <br />
                                    <b>Reporting</b> 
                                    <br /> <br />
                                    Timing: Approximately 3 - 4 Weeks




                                </Typography>

                            </Stack>

                            <Stack width={"100%"} alignItems={"center"}>
                                <img src={noCode} alt="Location Image" style={{ width: "100%" }} />


                            </Stack>

                        </Stack>
                    </Stack>
                </Box>


                {/* Why Choose DataSnack? */}
                <Box sx={{ backgroundColor: "#f4f3ec" }}>

                    <Stack direction={window.$isMobile ? "column" : "row"} width={"100%"}

                        justifyContent={"space-between"}
                        spacing={5}
                        paddingX={window.$isMobile ? 2.5 : 10}
                        paddingY={10}
                        alignItems={"center"}

                    >

                        <Stack width={"100%"}>
                            <Typography
                                // paddingRight={50}
                                // paddingX={window.$isMobile ? 2.5 : 10}
                                variant={'h4'}
                                sx={{

                                    typography: { fontFamily: "Roboto", fontWeight: 900 },
                                    textAlign: "left",
                                    lineHeight: '105%', color: "#000000"
                                }} >
                                Why Choose DataSnack?
                            </Typography>
                            <Typography
                                marginTop={2}
                                variant={'body1'}
                                sx={{

                                    typography: { fontFamily: "Roboto", fontWeight: 400 },
                                    textAlign: "left",
                                    lineHeight: '105%', color: "#000000"
                                }} >
Choose DataSnack to revolutionize your employee engagement and operational efficiency. Our innovative AI chatbot solutions ensure seamless, accurate, and effective interactions that keep your organization running smoothly. Plus, with our hassle-free onboarding process, there’s no need for technical expertise—we handle everything for you.  
                                <ul>
                                    <li><b>Effortless Risk Management</b>: Our AI chatbot mitigates risks by providing accurate and compliant responses, safeguarding your organization's reputation.    </li>
                                    <br /><li><b>Reliability and Accuracy</b>: With our built-in DataSnack Hallucination Prevention Module, you can trust that every interaction is both accurate and appropriate. </li>
                                    <br /><li><b>Continuous Optimization</b>: DataSnack continually sources domain information to keep your AI responses up to date, ensuring your chatbot remains relevant and informed without manual intervention.  </li>
                                </ul>
                                Experience the future of employee engagement with DataSnack, where innovation, reliability, and effortless automation converge to drive operational efficiency and success.  
                            </Typography>

                        </Stack>

                        <Stack width={"100%"} alignItems={"center"}>
                            <img src={ds_side} alt="Location Image" style={{ width: "45%" }} />


                        </Stack>

                    </Stack>

                </Box>

                {/* Simple, transparent pricing */}
                <Box sx={{ backgroundColor: "#ffff66" }}>
                    <Stack paddingX={window.$isMobile ? 2.5 : 20}
                        paddingY={10} spacing={5}>



                        <Stack
                            sx={{ backgroundColor: "#ffffff" }}>


                            <Stack direction={window.$isMobile ? "column" : "row"} width={"100%"}
                                paddingX={2.5}
                                paddingY={7.5}
                                justifyContent={"center"}
                                alignItems={"center"}
                                spacing={window.$isMobile ? 5 : 2}
                                sx={{ border: 1, borderColor: "#212325" }}
                            >
                                <Stack width={"100%"} direction={"column"} spacing={5} alignItems={"center"}>

                                    <Typography
                                        variant={'h4'}
                                        paddingLeft={window.$isMobile ? 0 : 7.5}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 500 },
                                            textAlign: window.$isMobile ? "center" : "left", fontStyle: "italic",
                                            lineHeight: '105%', color: "#00000"
                                        }} >
                                        Simple, transparent pricing


                                    </Typography>


                                </Stack>




                                <Stack width={"100%"} direction={"column"} spacing={3} alignItems={"center"} >
                                    <Typography
                                        marginTop={2}
                                        variant={'h2'}
                                        sx={{

                                            typography: { fontFamily: "Roboto", fontWeight: 900 },
                                            textAlign: "center",
                                            lineHeight: '105%', color: "#00000"
                                        }} >
                                        Starting at <br />$149 / mo.


                                    </Typography>
                                    <Button
                                        variant='outlined'
                                        onClick={() => {
                                            handleScreenChangeClick({
                                                selectedIndex: "Contact"

                                            })
                                        }} sx={{ borderColor: "#000000 ", width: window.$isMobile ? "100%" : "300px", height: "45px" }}>
                                        <Typography
                                            variant={'h7'}
                                            sx={{

                                                textTransform: "none",
                                                typography: { fontFamily: "Roboto", fontWeight: 700 },

                                                color: "#000000"
                                            }} >
                                            Contact DataSnack

                                        </Typography>
                                    </Button>

                                </Stack>

                            </Stack>
                        </Stack>


                    </Stack>
                </Box>

                {/* Implement DataSnack's AI Chatbot today to elevate engagement and differentiate your business in the industry. */}
                <Box sx={{

                    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.05'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
                    backgroundColor: "#212325",


                }}>
                    <Stack paddingX={2.5}
                        paddingY={20} direction={"column"} width={"100%"}
                        spacing={2}
                        alignItems={"center"}

                    >

                        <Typography
                            variant={'h3'}
                            paddingX={window.$isMobile ? 0 : 10}
                            sx={{

                                typography: { fontFamily: "Roboto", fontWeight: 300 },
                                textAlign: "center",
                                lineHeight: '105%', color: "#ffffff"
                            }} >
                            Get started with <b><span style={{ color: "#66a8da" }}>DataSnack's AI Chatbot </span></b> today to enhance employee interactions and manage risks effortlessly!
                        </Typography>
                        <Typography
                            variant={'h6'}
                            paddingBottom={2}
                            paddingX={window.$isMobile ? 0 : 10}
                            sx={{

                                typography: { fontFamily: "Roboto", fontWeight: 300 },
                                textAlign: "center",
                                lineHeight: '105%', color: "#ffffff"
                            }} >
                            Schedule a demo and see how our innovative, story-driven approach can unlock deeper insights and drive meaningful connections with your audience. <br /><br /><b>Start your journey with us now!</b>
                        </Typography>

                        <Button
                            variant='outlined'
                            onClick={() => {
                                handleScreenChangeClick({
                                    selectedIndex: "Contact"

                                })
                            }} sx={{ borderColor: "#ffffff ", width: window.$isMobile ? "100%" : "200px", height: "45px" }}>
                            <Typography
                                variant={'h7'}
                                sx={{

                                    textTransform: "none",
                                    typography: { fontFamily: "Roboto", fontWeight: 700 },

                                    color: "#ffffff"
                                }} >
                                Contact DataSnack

                            </Typography>
                        </Button>
                    </Stack>


                </Box>
                <Footer handleScreenChangeClick={handleScreenChangeClick} backgroundColor={"#000000"} />
            </ThemeProvider>

        </div >
    )

}

export default HomePageChat





